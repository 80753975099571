import { getUnixTime } from "date-fns";
import { B2CFilters } from "./types";
import {
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_START_DATE,
  USER_FILTER_TREATMENT_LOCATION,
  USER_FILTER_HAS_CAPACITY,
} from "../consts";

const todaysDate = getUnixTime(new Date());
export const DEFAULT_SEARCH_DISTANCE = 30;
export const DEFAULT_SEARCH_STARTDATE = todaysDate;
export const DEFAULT_SEARCH_ZIPCODE = "49661";
export const DEFAULT_SEARCH_TREATMENTLOCATION =
  "49661, Cloppenburg, Niedersachsen";

export const initialStateB2C: B2CFilters = {
  [USER_FILTER_DISTANCE_FROM_PROVIDER]: DEFAULT_SEARCH_DISTANCE,
  [USER_FILTER_START_DATE]: DEFAULT_SEARCH_STARTDATE,
  [USER_FILTER_TREATMENT_LOCATION]: {
    value: DEFAULT_SEARCH_ZIPCODE,
    label: DEFAULT_SEARCH_TREATMENTLOCATION,
  },
  [USER_FILTER_HAS_CAPACITY]: true,
};
