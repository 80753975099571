import { pathToUrl } from "core/model/utils/urls";
import { B2CFilters } from "dsl/organisms/Filters/B2CApp/types";
import toFilterQueryString from "dsl/organisms/Filters/utils/toQueryString";
import { Location, NavigateFunction } from "react-router-dom";

export const getB2CNavigationUrls = ({ location }: { location: Location }) => {
  return {
    goToOnboarding({ page = "welcome" }: { page?: string }) {
      return pathToUrl({
        pathname: `/b2c/onboarding/${page}`,
        search: location.search,
      });
    },
    goToDashboard({ filters }: { filters: B2CFilters }) {
      return pathToUrl({
        pathname: "/b2c/dashboard",
        search: toFilterQueryString(filters),
      });
    },
  };
};

export const getB2CNavigationHandlers = ({
  location,
  navigate,
}: {
  location: Location;
  navigate: NavigateFunction;
}) => {
  const urls = getB2CNavigationUrls({
    location,
  });

  return {
    goToOnboarding({ page = "welcome" }: { page?: string }) {
      navigate(urls.goToOnboarding({ page }));
    },
    goToDashboard({ filters }: { filters: B2CFilters }) {
      navigate(urls.goToDashboard({ filters }));
    },
  };
};
