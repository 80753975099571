import { DocumentNode, useApolloClient } from "@apollo/client";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { AnyObject, QueryProgress, QueryProgressActive } from "core/types";
import Spinner from "ds_legacy/components/Spinner";
import ErrorPage from "dsl/ecosystems/ErrorPage";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Translations from "translations/types";

export type QuerySignature = {
  query: DocumentNode;
  variables?: AnyObject;
};

export function composeProgress<OnlyLoading extends boolean = false>(
  progresses: Array<QueryProgress>,
  onlyLoading?: OnlyLoading,
): OnlyLoading extends true ? QueryProgressActive : QueryProgress {
  return (
    onlyLoading
      ? progresses.includes(QUERY_PROGRESS_PENDING)
        ? QUERY_PROGRESS_PENDING
        : QUERY_PROGRESS_NOT_STARTED
      : progresses.includes(QUERY_PROGRESS_PENDING)
      ? QUERY_PROGRESS_PENDING
      : progresses.includes(QUERY_PROGRESS_FAILED)
      ? QUERY_PROGRESS_FAILED
      : progresses.includes(QUERY_PROGRESS_SUCCEED)
      ? QUERY_PROGRESS_SUCCEED
      : QUERY_PROGRESS_NOT_STARTED
  ) as OnlyLoading extends true ? QueryProgressActive : QueryProgress;
}

export function isLoading(progress: QueryProgress | QueryProgress[]): boolean {
  if (Array.isArray(progress)) {
    return progress.some((p) => p === QUERY_PROGRESS_PENDING);
  }
  return progress === QUERY_PROGRESS_PENDING;
}

export function logError(error: AnyObject) {
  const isMock = error.networkError?.message?.includes(
    "No more mocked responses",
  );
  if (!isMock) console.error("[Apollo] ", error);
}

export function Result<Data>({
  children,
  data,
  ErrorComponent,
  flex,
  getErrorLabel,
  id,
  LoadingComponent,
  optimisticPolling,
  PlaceholderComponent,
  queryProgress,
}: {
  ErrorComponent?: ReactNode;
  LoadingComponent?: ReactNode;
  PlaceholderComponent?: ReactNode;
  children: (data: Data) => ReactNode;
  data: Data | undefined;
  flex?: boolean;
  getErrorLabel?: (translations: Translations) => string;
  id: string;
  optimisticPolling?: boolean;
  queryProgress: QueryProgress;
}) {
  const [didSucceed, setDidSucceed] = useState(false);

  useEffect(() => {
    if (queryProgress === QUERY_PROGRESS_SUCCEED) {
      if (data === null) {
        console.error(
          `[Result] null value returned from handler. ID: ${id}. Prefer returning an empty array for multiple resources.
          If this is a single resource, something else might have went wrong`,
        );
      }

      setDidSucceed(true);
    }
  }, [queryProgress]);

  // if the query succeeded at least once and the component
  // is polling, always render the last valid results
  if (optimisticPolling && didSucceed && data != null) {
    return <>{children(data)}</>;
  }

  if (queryProgress === QUERY_PROGRESS_PENDING) {
    return LoadingComponent ? <>{LoadingComponent}</> : <Spinner id={id} />;
  }

  if (queryProgress === QUERY_PROGRESS_FAILED) {
    return (
      <ErrorPage
        ErrorComponent={ErrorComponent}
        flex={flex}
        getErrorLabel={getErrorLabel}
        pageName={`Result Error - ${id}`}
      />
    );
  }

  if (queryProgress === QUERY_PROGRESS_NOT_STARTED) {
    return PlaceholderComponent ? <>{PlaceholderComponent}</> : null;
  }

  if (data == null)
    return (
      <ErrorPage
        getErrorLabel={(translations) => translations.general.noResult}
      />
    );

  return <>{children(data as Data)}</>;
}

export function useInvalidateCache() {
  const client: AnyObject = useApolloClient();
  const invalidate = useCallback(
    (partialEntries: string[]) => {
      Object.keys(client.cache.data.data).forEach((key) => {
        if (partialEntries.every((partial) => key.includes(partial)))
          client.cache.data.delete(key);
      });
    },
    [client],
  );
  return invalidate;
}

export function useInvalidateQuery() {
  const client = useApolloClient();
  const invalidate = useCallback(
    ({ fieldName }: { fieldName: string }) => {
      client.cache.evict({ id: "ROOT_QUERY", fieldName });
      client.cache.gc();
    },
    [client],
  );

  return invalidate;
}
