import { useProviderSearchContext } from "context/ProviderSearchContext";
import {
  getAcpHandlerUrls,
  getAcpNavigationHandlers,
} from "entries/acp/Router/acpNavigation";
import {
  getB2CNavigationHandlers,
  getB2CNavigationUrls,
} from "entries/b2c/Router/b2cNavigationHandlers";
import {
  getProviderSearchNavigationHandlers,
  getProviderSearchNavigationUrls,
} from "entries/providersearch/Router/providerSearchNavigationHandlers";
import {
  getCareproviderHandlerUrls,
  getCareproviderNavigationHandlers,
} from "entries/receiver/Router/navigation";
import {
  getCareseekerHandlerUrls,
  getCareseekerNavigationHandlers,
  getPatientHomeUrls,
  useEnhancedNavigate,
} from "entries/sender/Router/appNavigationHandlers";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedCareproviderId } from "reduxentities/selectors/hooks";

export type CareseekerNavigationHandlers = ReturnType<
  typeof useCareseekerNavigationHandlers
>;

export type CareproviderNavigationHandlers = ReturnType<
  typeof useCareproviderNavigationHandlers
>;

export type CareproviderNavigationUrls = ReturnType<
  typeof useCareproviderHandlerUrls
>;

export type AcpNavigationHandlers = ReturnType<typeof useAcpNavigationHandlers>;
export type B2CNavigationHandlers = ReturnType<typeof getB2CNavigationHandlers>;
export type ProviderSearchNavigationHandlers = ReturnType<
  typeof getProviderSearchNavigationHandlers
>;

// Careseeker
export function useCareseekerNavigationHandlers() {
  const navigate = useEnhancedNavigate();
  const location = useLocation();

  return getCareseekerNavigationHandlers({ location, navigate });
}

export function useCareseekerPatientHomeUrls() {
  const location = useLocation();

  return getPatientHomeUrls({ location });
}

export function useCareseekerHandlerUrls() {
  const location = useLocation();

  return getCareseekerHandlerUrls({ location });
}

// Careprovider
export function useCareproviderNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  const careproviderId = useLoggedCareproviderId();

  return getCareproviderNavigationHandlers({
    navigate,
    location,
    careproviderId,
  });
}

export function useCareproviderHandlerUrls() {
  const location = useLocation();
  const loggedCareproviderId = useLoggedCareproviderId();

  return getCareproviderHandlerUrls({ location, loggedCareproviderId });
}

// ACP
export function useAcpNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  return getAcpNavigationHandlers({ navigate, search: location.search });
}

export function useAcpHandlerUrls() {
  const location = useLocation();

  return getAcpHandlerUrls({ location });
}

// B2C
export function useB2CNavigationUrls() {
  const location = useLocation();
  return getB2CNavigationUrls({ location });
}

export function useB2CNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  return getB2CNavigationHandlers({ navigate, location });
}

// Provider Search
export function useProvidersearchNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useProviderSearchContext();

  return getProviderSearchNavigationHandlers({
    navigate,
    location,
    providerSearchContext: context,
  });
}

export function useProvidersearchNavigationUrls() {
  const location = useLocation();
  const context = useProviderSearchContext();

  return getProviderSearchNavigationUrls({
    location,
    providerSearchContext: context,
  });
}
