import { GenericErrorPage } from "dsl/atoms/ErrorPages";
import RedirectRoot from "dsl/atoms/RedirectRoot";
import lazy from "dsl/atoms/retry";
import B2CHeader from "dsl/ecosystems/B2CDashboard/B2CHeader";
import { DEFAULT_ONBOARDING_PAGE } from "dsl/ecosystems/B2COnboarding";
import { Navigate, Route, Routes } from "react-router-dom";

const B2CDashboardPage = lazy(() => import("dsl/ecosystems/B2CDashboard"));
const ProviderDetailsPage = lazy(
  () => import("dsl/ecosystems/ProviderDetailsPage"),
);
const SPNDetailsPage = lazy(
  () => import("dsl/ecosystems/ProviderDetailsPage/SPNDetailsPage"),
);
const B2COnboarding = lazy(() => import("dsl/ecosystems/B2COnboarding"));

const defaultRedirectPath = `/b2c/onboarding/${DEFAULT_ONBOARDING_PAGE}`;

export default function B2CRoutes() {
  return (
    <Routes>
      <Route path="error" element={<GenericErrorPage />} />
      <Route index element={<Navigate to={defaultRedirectPath} replace />} />
      <Route path="/b2c/*" element={<B2CHeader />}>
        <Route path="dashboard" element={<B2CDashboardPage />} />
        <Route path="provider">
          <Route index element={<ProviderDetailsPage />} />
          <Route path=":providerId" element={<ProviderDetailsPage />} />
        </Route>
        <Route path="spn">
          <Route index element={<SPNDetailsPage />} />
          <Route path=":zipcode" element={<SPNDetailsPage />} />
        </Route>
        <Route path="onboarding">
          <Route path=":page" element={<B2COnboarding />} />
          <Route
            path="*"
            element={<Navigate to={defaultRedirectPath} replace />}
          />
        </Route>
        <Route path="*" element={<RedirectRoot to={defaultRedirectPath} />} />
      </Route>
    </Routes>
  );
}
