import RSButton from "ds_legacy/components/RSButton";
import { TILE_WIDTH } from "ds_legacy/components/TileButton";
import { APP_BACKGROUND } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  dp,
  margin,
  padding,
  responsiveCSS,
  sizing,
} from "ds_legacy/materials/metrics";
import {
  Caption,
  FONT_SIZE_24,
  FONT_SIZE_30,
  Heading,
} from "ds_legacy/materials/typography";
import {
  B2CFilterActions,
  B2CFilters,
} from "dsl/organisms/Filters/B2CApp/types";
import { ComponentProps, FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";

export type PageProps = {
  filters: B2CFilters;
  navigateToPage: (destination: Page) => void;
  setFilters: React.Dispatch<B2CFilterActions>;
  translations: Translations;
};

export const PAGES = {
  WELCOME: "welcome",
  TARGET: "target",
  START_DATE: "startdate",
  CARE_TYPE: "careType",
  CARE_SOLUTION: "careSolution",
  CARE_TERM: "careTerm",
  LOCATION: "location",
  LOADING: "loading",
  DAILY_LIVING_SUPPORT_SERVICES: "dailyLivingSupportServices",
} as const;

export type Page = (typeof PAGES)[keyof typeof PAGES];

export const testIds = {
  [PAGES.LOADING]: "loading-page",
  [PAGES.LOCATION]: "location-page",
  locationInput: "location",
  [PAGES.WELCOME]: "welcome-page",
  [PAGES.TARGET]: "target-page",
  [PAGES.CARE_TYPE]: "caretype-page",
  [PAGES.CARE_SOLUTION]: "caresolution-page",
  [PAGES.CARE_TERM]: "careterm-page",
  [PAGES.START_DATE]: "startdate-page",
  [PAGES.DAILY_LIVING_SUPPORT_SERVICES]: "dailylivingsupportservices-page",

  action: "cta-action",
};

export const PAGE_MAX_WIDTH = sizing(51);
export const MOBILE_MAX_WIDTH = sizing(43);
const STEPPER_HEIGHT = 92;
const STEPPER_HEIGHT_MOBILE = 62;
export const PageWrapper = styled(VerticalLayout)`
  position: relative;
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH};
  margin: ${margin(0, 2)};
  ${({ withBackground }: { withBackground?: boolean }) =>
    !withBackground &&
    css`
      ${responsiveCSS({
        mobile: [`min-height: calc(100% - ${dp(STEPPER_HEIGHT_MOBILE)})`],
        desktop: [`min-height: calc(100% - ${dp(STEPPER_HEIGHT)})`],
      })};
    `};
  ${responsiveCSS({
    mobile: [`max-width: ${MOBILE_MAX_WIDTH}`],
  })};
`;

export const PageHeading = styled(Heading)`
  margin: ${margin(3, 1, 5, 1)};
  font-size: ${FONT_SIZE_30};
  ${responsiveCSS({
    mobile: [`font-size: ${FONT_SIZE_24}`, `margin: ${margin(0, 1, 2, 1)};`],
  })};
`;

const SkipButtonWrapper = styled.div`
  margin: auto 0 ${sizing(3)} 0;
  ${responsiveCSS({
    tablet: [`margin: auto auto 0 auto`],
  })};
  button {
    min-width: unset !important;
    ${responsiveCSS({
      tablet: [`margin: auto`],
    })};
  }
`;

export function SkipButton({
  onClick,
  translations,
}: {
  onClick: () => void;
  translations: Translations;
}) {
  return (
    <SkipButtonWrapper>
      <RSButton
        color="grey"
        id="skip"
        loading="na"
        onClick={onClick}
        style={{ textTransform: "none", margin: 0 }}
        variant="text"
      >
        {translations.bToC.onboardingScreens.buttonSkip}
      </RSButton>
    </SkipButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  ${responsiveCSS({
    mobile: [
      `width: calc(100% - ${sizing(2)})!important`,
      `margin: auto!important`,
    ],
    tablet: [`width: ${TILE_WIDTH}!important`, `margin: auto!important`],
  })};
  button {
    text-transform: none !important;
    margin: 0 !important;
  }
  .MuiButton-label {
    ${responsiveCSS({
      desktop: [`justify-content: flex-start!important`],
    })};
  }
`;

export function DontKnowButton({
  onClick,
  translations,
}: {
  onClick: () => void;
  translations: Translations;
}) {
  return (
    <ButtonWrapper>
      <RSButton
        color="primary"
        id="dont_know"
        loading="na"
        onClick={onClick}
        RightIcon="forward"
        variant="text"
      >
        {translations.bToC.onboardingScreens.buttonDontKnow}
      </RSButton>
    </ButtonWrapper>
  );
}

export default function OnboardingCard({
  children,
  navigateToPage,
  onNext,
  title,
  withBackground,
  ...props
}: {
  children: React.ReactNode;
  navigateToPage?: (destination: Page) => void;
  onNext?: Function;
  title: string;
  withBackground?: boolean;
}) {
  const translations = useTranslations();
  return (
    <PageWrapper withBackground={withBackground} {...props}>
      <PageHeading primary bold>
        {title}
      </PageHeading>
      {children}
      {onNext && (
        <DontKnowButton translations={translations} onClick={() => onNext()} />
      )}
      {navigateToPage && (
        <SkipButton
          translations={translations}
          onClick={() => navigateToPage(PAGES.LOADING)}
        />
      )}
    </PageWrapper>
  );
}
export const StepperPage: FC<
  React.PropsWithChildren<{
    background: ReactNode;
    showBackground: boolean;
    stepper: ReactNode;
  }>
> = ({ background, children, showBackground, stepper }) => {
  return (
    <StepperLayout showBackground={showBackground}>
      {showBackground && background}
      <StepperPageWrapper showBackground={showBackground}>
        {stepper}
        {children}
      </StepperPageWrapper>
    </StepperLayout>
  );
};

const StepperLayout = styled(HorizontalLayout)<
  ComponentProps<typeof HorizontalLayout> & { showBackground: boolean }
>`
  height: 100%;
  ${({ showBackground }: { showBackground: boolean }) =>
    responsiveCSS({
      tablet: [
        `flex-direction: ${showBackground ? "column-reverse" : "column"}`,
        `justify-content: flex-start`,
      ],
    })};
`;

export const StepperPageWrapper = styled(VerticalLayout)`
  width: ${({ showBackground }: { showBackground: boolean }) =>
    showBackground ? "55%" : "100%"};
  align-items: center;
  background-color: ${APP_BACKGROUND};
  ${responsiveCSS({
    tablet: [`padding: ${padding(0)}`, `width: 100%`],
  })};
  ${({ showBackground }: { showBackground: boolean }) =>
    showBackground
      ? css`
          ${responsiveCSS({
            tablet: [`height: initial`],
            desktop: [`min-width: calc(100vw - ${MAX_WIDTH_BACKGROUND}px)`],
          })};
        `
      : css`
          ${responsiveCSS({
            tablet: [`height: 100%`],
          })};
        `}
`;

const MAX_WIDTH_BACKGROUND = 600;
export const BackgroundImage = styled.div`
  height: 100%;
  position: relative;
  ${({ image }: { image: string }) =>
    image &&
    css`
      width: 45%;
      max-width: ${dp(MAX_WIDTH_BACKGROUND)};
      background-image: url(${image});
      background-position: center;
      background-size: cover;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(94, 206, 220, 0.2);
      }
    `};
  ${responsiveCSS({
    tablet: [`display: none;`],
  })};
`;

export const StyledCaption = styled(Caption)`
  ${responsiveCSS({
    mobile: [`margin: 0 auto`, `width: calc(100% - ${sizing(2)})`],
    tablet: [`margin: 0 auto`, `width: ${TILE_WIDTH}`],
    desktop: [`margin: ${margin(0, 1)}`],
  })};
`;
