import {
  ApolloClient,
  FetchPolicy,
  QueryHookOptions,
  WatchQueryFetchPolicy,
  useApolloClient,
  useQuery,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useErrorHandler } from "apollo/mutations/utils";
import {
  ACTIVATE_PROVIDER_SEARCH_ACCOUNT,
  GET_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNTS_FOR_CARESEEKER,
  GET_ADDRESS_FROM_ZIPCODE,
  GET_AUCTION,
  GET_BEDROCK_PLAYGROUND,
  GET_CAREPROVIDER,
  GET_CAREPROVIDER_DPA,
  GET_CAREPROVIDER_FILES,
  GET_CAREPROVIDER_INFO,
  GET_CAREPROVIDER_LISTING,
  GET_CAREPROVIDER_LISTING_DETAILS,
  GET_CAREPROVIDER_NOTIFICATIONS,
  GET_CAREPROVIDER_PATIENT_REQUEST,
  GET_CAREPROVIDER_REQUESTS_COUNT,
  GET_CAREPROVIDER_REQUESTS_NEW,
  GET_CAREPROVIDER_REVERSE_SEARCHES,
  GET_CAREPROVIDER_TOKEN,
  GET_CAREPROVIDER_WARNINGS,
  GET_CARESEEKER,
  GET_CARESEEKER_ACCOUNTS,
  GET_CARESEEKER_PATIENTS_COUNT,
  GET_COMPANY,
  GET_COMPANY_TOKEN,
  GET_CONSULTANT,
  GET_CONSULTANT_EVENTS,
  GET_CONSULTANT_FILES,
  GET_EMAIL_TRANSLATION,
  GET_EMAIL_TRANSLATIONS,
  GET_ENCRYPTION_JOBS,
  GET_EVENTS,
  GET_FILES,
  GET_INSURANCE,
  GET_ONPREMISE_BUNDLE_LINK,
  GET_ONPREMISE_BUNDLE_LINK_CARESEEKER,
  GET_ONPREMISE_CONFIG,
  GET_ONPREMISE_CONFIG_FOR_CARESEEKER,
  GET_ONPREMISE_IMPORTS,
  GET_PATIENT,
  GET_PATIENTS_NEW_LIGHT,
  GET_PRODUCT_TOUR,
  GET_PROVIDER_SEARCH_CONSULTANT,
  GET_PROVIDER_SEARCH_LISTING,
  GET_PROVIDER_SEARCH_PATIENTS,
  GET_PROVIDER_SEARCH_PROVIDER,
  GET_PROVIDER_SEARCH_REQUEST_OVERVIEW,
  GET_REHAB_FORMS,
  GET_SEARCH_MERGE_MAP_REQUESTS,
  GET_SEARCH_MERGE_REQUESTS,
  GET_SEARCH_MERGE_TOTALS,
  GET_SELECTABLE_CAREPROVIDERS,
  GET_SENDER_RECEIVER_NOTE,
  GET_SENDER_RECEIVER_RULES,
  GET_SENDER_USER_MANAGEMENT_FILE,
  GET_STATIC_SEARCH_CANDIDATES,
  GET_TRANSITIONAL_CARE,
  GET_TRANSITIONAL_CARE_REQUESTS,
  GET_TRANSLATION,
  GET_TRANSLATIONS,
  GET_VERSIONS,
  LOGIN_ACTIVATION_PROVIDER_SEARCH_ACCOUNT,
  SEALD_GROUP_SESSIONS_CHECK,
  ZIPCODE_INSIDE_CATCHMENT,
} from "core/apollo/graphql";
import {
  QUERY_PROGRESS_NOT_STARTED,
  SEARCH_MERGE_PRINT_SECTIONS_PARAM,
  SEARCH_TABLE_ACCEPTED,
  SEARCH_TABLE_CONTACTED,
  SEARCH_TABLE_DECLINED,
  SEARCH_TABLE_FILTERED,
  SEARCH_TABLE_POTENTIAL,
  SEARCH_TABLE_REJECTED,
  SEARCH_TABLE_ROW_COUNT_10,
  SEARCH_TABLE_SORTING_DEFAULT,
  SEARCH_TABLE_TABLES,
  SEARCH_TABLE_VALIDATED,
  SORTING_MAP,
  TABLE_PAYLOAD_ACCEPTED,
  TABLE_PAYLOAD_CONTACTED,
  TABLE_PAYLOAD_DECLINED,
  TABLE_PAYLOAD_FILTERED,
  TABLE_PAYLOAD_POTENTIAL,
  TABLE_PAYLOAD_REJECTED,
  TABLE_PAYLOAD_VALIDATED,
} from "core/consts";
import { useSafeState } from "core/hooks";
import { useApolloEncryptionContext } from "core/model/patients/encryption/utils";
import { getErrorMessage } from "core/model/utils/errors";
import { objectToQueryParamString } from "core/model/utils/strings";
import {
  AcceptedTablePayload,
  Account,
  ApolloCacheData,
  Auction,
  AuctionRequest,
  BedrockPlaygroundPage,
  Candidates,
  Careprovider,
  CareproviderDashboardCounts,
  CareproviderFiles,
  Careseeker,
  ClinicDashboardCounts,
  Company,
  ConsultantEventPage,
  ContactedTablePayload,
  DeclinedTablePayload,
  EncryptionJob,
  Event,
  FileParams,
  Filev2,
  FilteredTablePayload,
  GetAccountsForCareseekerPayload,
  GetAccountsForCareseekerVariables,
  GetUserManagmentFileParams,
  GroupSessionsResponse,
  Insurance,
  Location,
  NotificationEvent,
  OnPremImport,
  OnpremiseConfig,
  Page,
  Patient,
  PotentialReceiversTablePayload,
  ProductTourCompletion,
  ProviderBlacklistingRule,
  ProviderListing,
  ProviderListingDetails,
  ProviderSearchActivation,
  ProviderSearchListing,
  ProviderSearchPatients,
  ProviderSearchProvider,
  ProviderSearchRequestsOverview,
  RehabForms,
  RejectedTablePayload,
  RequestPage,
  SearchMergePayload,
  SearchTable,
  SearchTablePagination,
  SearchTableTotals,
  SelectableCareprovider,
  SenderReceiverNote,
  TableCount,
  TablePageState,
  TablePayload,
  TableSortValue,
  TransitionalCare,
  TransitionalCareImportedAuctionRequest,
  ValidatedTablePayload,
  Version,
  WarningsPayload,
} from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import {
  EmailTranslation,
  TranslationType,
} from "dsl/ecosystems/AcpTranslations/types";
import { useProviderSearchFilterContext } from "dsl/molecules/ProviderSearchAppWrapper";
import { ProductTourKey } from "dsl/molecules/useProductTour";
import { B2CFilters } from "dsl/organisms/Filters/B2CApp/types";
import { ProviderSearchFilters } from "dsl/organisms/Filters/ProviderSearchApp/types";
import {
  ReceiverFilters,
  ReverseSearchResult,
} from "dsl/organisms/Filters/ReceiverApp/types";
import { SenderFilters } from "dsl/organisms/Filters/SenderApp/types";
import {
  USER_FILTER_START_DATE,
  USER_FILTER_TREATMENT_LOCATION,
} from "dsl/organisms/Filters/consts";
import toFilterQueryString from "dsl/organisms/Filters/utils/toQueryString";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslations } from "translations";
import { useHandleAutoRefreshComponent } from "../subscriptions";
import { getFetchPolicy } from "../utils";
import {
  getQueryProgress,
  useGenericQuery,
  useLazyQuery,
  usePolling,
} from "./utils";

export const useGetPatientQuery = ({
  decrypt,
  options = {},
  patientId,
  polling = false,
}: {
  decrypt?: boolean;
  options?: QueryHookOptions<{ patient: Patient }, { id: number }>;
  patientId: number;
  polling?: boolean;
}) => {
  const encryptionContext = useApolloEncryptionContext(
    decrypt ? { decrypt } : undefined,
  );

  const { data, error, loading, refetch, startPolling, stopPolling } = useQuery<
    { patient: Patient },
    { id: number }
  >(GET_PATIENT, {
    context: { encryptionContext },
    variables: { id: patientId },
    ...options,
  });
  usePolling({ shouldPoll: polling, startPolling, stopPolling });

  return [
    getQueryProgress(loading, error, options.skip),
    data?.patient,
    { error, refetch },
  ] as const;
};

export const useGetNewPatientsQuery = ({
  careseekerId,
  count,
  filters,
  start,
}: {
  careseekerId: number | null;
  count?: number;
  filters: SenderFilters;
  start?: number;
  statusList?: Array<number>;
}) => {
  const queryArguments = useMemo(() => {
    let queryFilters = filters
      ? toFilterQueryString(filters, { byTab: true })
      : "";
    if (start != null && count)
      queryFilters = `${queryFilters}&start=${start}&count=${count}`;
    return queryFilters;
  }, [start, count, filters]);

  const variables = useMemo(
    () => ({
      id: careseekerId,
      arguments: queryArguments,
    }),
    [careseekerId, queryArguments],
  );

  const encryptionContext = useApolloEncryptionContext({ decrypt: true });
  const { data, error, loading, refetch } = useQuery<{
    patientsNew: {
      patients: Array<Patient>;
      remaining: number;
      total: number;
    };
  }>(GET_PATIENTS_NEW_LIGHT, {
    variables,
    context: { encryptionContext },
  });

  return [
    getQueryProgress(loading, error),
    data?.patientsNew,
    { refetch, error },
  ] as const;
};

export const useGetRequestsForProviderQuery = ({
  count,
  filters,
  providerId,
  start,
}: {
  count?: number;
  filters: ReceiverFilters;
  providerId: number | null | undefined;
  start?: number;
}) => {
  const encryptionContext = useApolloEncryptionContext({
    decrypt: true,
  });

  const queryArguments = useMemo(() => {
    let args = toFilterQueryString(filters, { byTab: true });

    if (start != null && count) args = `${args}&start=${start}&count=${count}`;

    return args;
  }, [count, filters, start]);

  const { data, error, loading, refetch } = useQuery<
    { careproviderRequestsNew: RequestPage },
    { arguments: string; id: number | null | undefined }
  >(GET_CAREPROVIDER_REQUESTS_NEW({ withRequestsLight: !start }), {
    variables: {
      id: providerId,
      arguments: queryArguments,
    },
    context: { encryptionContext },
  });

  return [
    getQueryProgress(loading, error),
    data?.careproviderRequestsNew,
    { error, refetch },
  ] as const;
};

export const useGetProviderListing = ({
  count,
  filters,
  start,
}: {
  count?: number;
  filters: B2CFilters;
  start?: number;
}) => {
  const errorHandler = useErrorHandler(onError);
  const toast = useToast();
  const translations = useTranslations();

  const queryArguments = useMemo(() => {
    let queryFilters = filters ? toFilterQueryString(filters) : "";
    if (start != null && count)
      queryFilters = `${queryFilters}&start=${start}&count=${count}`;
    return queryFilters;
  }, [start, count, filters]);

  const variables = useMemo(
    () => ({ filters: queryArguments }),
    [queryArguments],
  );

  const [queryProgress, data, rest] = useGenericQuery<
    {
      providerListing?: {
        careproviders: ProviderListing[];
        remaining: number;
        total: number;
      };
    },
    { filters: string }
  >(GET_CAREPROVIDER_LISTING, {
    variables,
    onError: (error) => {
      if (error.message.includes("400")) {
        toast({
          message: translations.bToC.providerList.errorBadFilters,
          color: "primary",
        });
        return;
      }
      return errorHandler(error);
    },
  });

  return [queryProgress, data?.providerListing, rest] as const;
};

export function useGetProviderListingDetails({
  careproviderId,
  filters,
}: {
  careproviderId: number;
  filters: B2CFilters;
}) {
  const queryArguments = toFilterQueryString(filters);
  const { data, error, loading, refetch } = useQuery<
    { providerListingDetails: ProviderListingDetails },
    { careproviderId: number; filters: string }
  >(GET_CAREPROVIDER_LISTING_DETAILS, {
    variables: {
      careproviderId,
      filters: queryArguments,
    },
  });

  const providerListingDetails = data?.providerListingDetails;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, providerListingDetails, { error, refetch }] as const;
}

export const useGetRequestsCountForProviderQuery = ({
  fetchPolicy = "no-cache",
  filters,
  nextFetchPolicy,
  providerId,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  filters?: ReceiverFilters;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  providerId: number | null | undefined;
}) => {
  const queryFilters = useMemo(
    () => (filters ? toFilterQueryString(filters, { byTab: false }) : ""),
    [filters],
  );

  const { data, error, loading, refetch, startPolling, stopPolling } = useQuery<
    { requestCount: CareproviderDashboardCounts },
    { filters: string; id: number | null | undefined }
  >(GET_CAREPROVIDER_REQUESTS_COUNT, {
    variables: {
      id: providerId,
      filters: queryFilters,
    },
    fetchPolicy,
    nextFetchPolicy,
  });

  usePolling({ startPolling, stopPolling, dependencies: [queryFilters] });

  const requestCount = data?.requestCount;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, requestCount, { error, refetch }] as const;
};

export const useGetPatientCounts = ({
  careseekerId,
  fetchPolicy = "no-cache",
  filters,
  nextFetchPolicy,
}: {
  careseekerId: number | null | undefined;
  fetchPolicy?: WatchQueryFetchPolicy;
  filters?: SenderFilters;
  nextFetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const queryFilters = useMemo(
    () => (filters ? toFilterQueryString(filters, { byTab: false }) : ""),
    [filters],
  );
  const { data, error, loading, refetch, startPolling, stopPolling } = useQuery<
    { patientCount: ClinicDashboardCounts },
    { filters: string; id: number | null | undefined }
  >(GET_CARESEEKER_PATIENTS_COUNT, {
    variables: {
      id: careseekerId,
      filters: queryFilters,
    },
    fetchPolicy,
    nextFetchPolicy,
  });

  usePolling({
    startPolling,
    stopPolling,
    dependencies: [queryFilters],
  });

  const patientCount = data?.patientCount;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, patientCount, { error, refetch }] as const;
};

export function useGetTranslation(
  id: string,
  options: QueryHookOptions<
    { translation: TranslationType },
    { id: string }
  > = {},
) {
  const { data, error, loading, refetch } = useQuery<
    { translation: TranslationType },
    { id: string }
  >(GET_TRANSLATION, {
    variables: { id },
    ...options,
  });

  const translation = data?.translation;
  const queryProgress = getQueryProgress(loading, error, options.skip);
  return [queryProgress, translation, { error, refetch }] as const;
}

export function useGetSenderReceiverRules(options: {
  [index: string]: number | undefined;
  careprovider_id?: number;
  careseeker_id?: number;
}) {
  const query = objectToQueryParamString(options);
  const { data, error, loading, refetch } = useQuery<
    { senderReceiverRules: ProviderBlacklistingRule[] },
    { params: string }
  >(GET_SENDER_RECEIVER_RULES, {
    variables: { params: query },
  });
  const senderReceiverRules = data?.senderReceiverRules;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, senderReceiverRules, { error, refetch }] as const;
}

export function useGetOnpremiseConfig({
  configId,
  onError,
}: QueryHookOptions<
  { onpremiseConfig: OnpremiseConfig },
  { configId: number | undefined }
> & {
  configId?: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { onpremiseConfig: OnpremiseConfig },
    { configId: number | undefined }
  >(GET_ONPREMISE_CONFIG, {
    variables: { configId },
    onError,
    skip: !configId || configId <= 0,
  });
  const queryProgress = getQueryProgress(loading, error);
  const onpremiseConfig = data?.onpremiseConfig;

  return [queryProgress, onpremiseConfig, { error, refetch }] as const;
}

export function useGetOnpremiseConfigForCareseeker({
  careseekerId,
  onError,
}: QueryHookOptions<
  { onpremiseConfig: OnpremiseConfig },
  { careseekerId: number }
> & {
  careseekerId: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { onpremiseConfig: OnpremiseConfig },
    { careseekerId: number }
  >(GET_ONPREMISE_CONFIG_FOR_CARESEEKER, {
    variables: { careseekerId },
    onError,
  });
  const queryProgress = getQueryProgress(loading, error);
  const onpremiseConfig = data?.onpremiseConfig;

  return [queryProgress, onpremiseConfig, { error, refetch }] as const;
}

export function useGetOnpremiseImportsForCareseeker({
  careseekerId,
  onError,
}: QueryHookOptions<
  { onpremiseConfig: OnPremImport[] },
  { careseekerId: number }
> & {
  careseekerId: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { onpremiseConfig: OnPremImport[] },
    { careseekerId: number }
  >(GET_ONPREMISE_IMPORTS, {
    variables: { careseekerId },
    onError,
  });
  const queryProgress = getQueryProgress(loading, error);
  const onpremiseConfig = data?.onpremiseConfig;

  return [queryProgress, onpremiseConfig, { error, refetch }] as const;
}

export function useGetTranslations(
  options: QueryHookOptions<{
    translations: TranslationType[];
  }> = {},
) {
  const { data, error, loading, refetch } = useQuery<{
    translations: TranslationType[];
  }>(GET_TRANSLATIONS, options);

  const translations = data?.translations;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, translations, { error, refetch }] as const;
}

export function useGetAccountsForCareseeker(
  options: QueryHookOptions<
    GetAccountsForCareseekerPayload,
    GetAccountsForCareseekerVariables
  >,
) {
  const { data, error, loading, refetch } = useQuery<
    GetAccountsForCareseekerPayload,
    GetAccountsForCareseekerVariables
  >(GET_ACCOUNTS_FOR_CARESEEKER, {
    fetchPolicy: getFetchPolicy(options.fetchPolicy),
    ...options,
  });

  const payload = data?.getAccountsForCareseeker
    ? {
        ...data.getAccountsForCareseeker,
        total_accounts: data.getAccountsForCareseeker.total_accounts ?? 0,
        total_filtered_accounts:
          data.getAccountsForCareseeker.total_filtered_accounts ?? 0,
      }
    : { accounts: [], total_accounts: 0, total_filtered_accounts: 0 };
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, payload, { error, refetch }] as const;
}

export function useGetInternalAccounts(
  options: QueryHookOptions<{
    accounts: Account[];
  }> = {},
) {
  const { data, error, loading, refetch } = useQuery<{
    accounts: Account[];
  }>(GET_ACCOUNTS, options);

  const accounts = data?.accounts;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, accounts, { error, refetch }] as const;
}

export function useGetCareseekerAccounts({
  careseekerId,
  ...options
}: { careseekerId: number } & QueryHookOptions<
  { accounts: Account[] },
  { id: number }
>) {
  const { data, error, loading, refetch } = useQuery<
    { accounts: Account[] },
    { id: number }
  >(GET_CARESEEKER_ACCOUNTS, {
    variables: { id: careseekerId },
    ...options,
  });

  const accounts = data?.accounts;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, accounts, { error, refetch }] as const;
}

export function useGetCareseeker(
  id: number | undefined,
  queryOptions: {
    withAdminFields?: boolean;
    withConfig?: boolean;
  } = {},
  options: QueryHookOptions<
    { careseeker: Careseeker },
    { id: number | undefined }
  > = {},
) {
  const normalizedQueryOptions = {
    withAdminFields: false,
    withConfig: false,
    ...queryOptions,
  };

  const { data, error, loading, refetch } = useQuery<
    { careseeker: Careseeker },
    { id: number | undefined }
  >(GET_CARESEEKER(normalizedQueryOptions), {
    variables: { id },
    skip: !id,
    ...options,
  });

  const careseeker = data?.careseeker;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, careseeker, { error, refetch }] as const;
}

export const useLazyGetCareseeker = ({
  fetchPolicy,
  queryOptions = {},
}: {
  fetchPolicy?: FetchPolicy;
  queryOptions?: {
    withAdminFields?: boolean;
    withConfig?: boolean;
  };
}) => {
  const [getCareseker] = useLazyQuery<{ careseeker: Careseeker }>(
    GET_CARESEEKER(queryOptions),
    { fetchPolicy },
  );

  return (id: number) => {
    return getCareseker({ id });
  };
};

export function useGetCareprovider({
  id,
  token,
  queryOptions = {},
  options = {},
}: {
  id: number | undefined;
  options?: QueryHookOptions<
    { careprovider: Careprovider },
    { id: number | undefined }
  >;
  queryOptions?: {
    withEvents?: boolean;
    withInternalNotes?: boolean;
    withMetrics?: boolean;
    withRoles?: boolean;
  };
  token?: string;
}) {
  const { data, error, loading, refetch } = useQuery<
    { careprovider: Careprovider },
    { id: number | undefined }
  >(
    token
      ? GET_CAREPROVIDER_TOKEN({ ...queryOptions, token })
      : GET_CAREPROVIDER(queryOptions),
    { variables: { id }, skip: !id, ...options },
  );

  const careprovider = data?.careprovider;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, careprovider, { error, refetch }] as const;
}

export function useGetSelectableCareprovidersLazy({
  id,
  ...options
}: {
  id?: number;
  options?: Parameters<typeof useLazyQuery>[1];
} = {}) {
  return useLazyQuery<
    { careproviders: { id: number; name: string }[] },
    { id?: number }
  >(GET_SELECTABLE_CAREPROVIDERS, {
    variables: { id },
    fetchPolicy: "network-only",
    ...options,
  });
}

export function useGetAccount(
  id: number,
  options: QueryHookOptions<{ account: Account }, { id: number }> = {},
) {
  const skip = id <= 0;
  const { data, error, loading, refetch } = useQuery<
    { account: Account },
    { id: number }
  >(GET_ACCOUNT, {
    variables: { id },
    skip,
    ...options,
  });

  const account = data?.account;
  const queryProgress = getQueryProgress(loading, error, skip);
  return [queryProgress, account, { error, refetch }] as const;
}

export function useGetCompany({
  id,
  token,
  options = {},
}: {
  id: number;
  options?: QueryHookOptions<
    { company: Company },
    { id: number; token?: string }
  >;
  token?: string;
}) {
  const { data, error, loading, refetch } = useQuery<
    { company: Company },
    { id: number; token?: string }
  >(token ? GET_COMPANY_TOKEN : GET_COMPANY, {
    variables: { id, token },
    ...options,
  });

  const company = data?.company;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, company, { error, refetch }] as const;
}

export function useLazyGetCompany() {
  const [getCompany] = useLazyQuery<{ company: Company }, { id: number }>(
    GET_COMPANY,
  );
  return getCompany;
}

export function useLazyGetAccount() {
  const [getAccount] = useLazyQuery<{ account: Account }, { id: number }>(
    GET_ACCOUNT,
    { fetchPolicy: getFetchPolicy("network-only") },
  );
  return async (id: number): Promise<Account | undefined> => {
    const { data } = await getAccount({ id });
    return data?.account;
  };
}

export function useGetInsurance(
  id: number,
  options: QueryHookOptions<{ insurance: Insurance }, { id: number }> = {},
) {
  const { data, error, loading, refetch } = useQuery<
    { insurance: Insurance },
    { id: number }
  >(GET_INSURANCE, {
    variables: { id },
    ...options,
  });

  const insurance = data?.insurance;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, insurance, { error, refetch }] as const;
}

export function getPatientEventQuery({
  auctionId,
  count,
  eventBlacklist,
  eventWhiteList,
  patientId,
  reverse = false,
  start,
  withEventLastSeens,
}: {
  auctionId?: number;
  count?: number;
  eventBlacklist?: Array<number>;
  eventWhiteList?: Array<number>;
  patientId: number;
  reverse?: boolean;
  start?: number;
  withEventLastSeens?: boolean;
}) {
  let query = reverse ? "reverse" : "";
  query += patientId != null ? `&patient_id=${patientId}` : "";
  query += auctionId != null ? `&auction_id=${auctionId}` : "";
  query += start != null ? `&start=${start}` : "";
  query += count != null ? `&count=${count}` : "";
  query += withEventLastSeens ? `&last_seens` : "";
  query += eventWhiteList ? `&event_types=${eventWhiteList.join(",")}` : "";
  query += eventBlacklist
    ? `&exclude_event_types=${eventBlacklist.join(",")}`
    : "";
  query += `&fields=careprovider`;

  const fetchPolicy: WatchQueryFetchPolicy = "network-only";

  return {
    query: GET_EVENTS({ withEventLastSeens }),
    fetchPolicy,
    variables: {
      params: query,
    },
  };
}

export function getAuctionRequestEventQuery({
  auctionRequestId,
  withEventLastSeens,
}: {
  auctionRequestId: number;
  withEventLastSeens?: boolean;
}) {
  const withEventLastSeensParam = withEventLastSeens ? `&last_seens` : "";

  const query = `reverse&auction_request_id=${auctionRequestId}${withEventLastSeensParam}&fields=careseeker,careprovider`;

  return {
    query: GET_EVENTS({ withEventLastSeens }),
    variables: {
      params: query,
    },
  };
}

const useGetEvents = ({
  count,
  eventBlacklist,
  eventWhiteList,
  fields,
  idParamName,
  idParamValue,
  start,
  withCounts = true,
}: {
  count?: number;
  eventBlacklist?: Array<number>;
  eventWhiteList?: Array<number>;
  fields?: string;
  idParamName: string;
  idParamValue: number;
  start?: number;
  withCounts?: boolean;
}) => {
  const idParam = `${idParamName}=${idParamValue}`;
  const startParam = start != null ? `&start=${start}` : "";
  const countParam = count != null ? `&count=${count}` : "";
  const withCountsParam = withCounts ? "" : "&without_counts";

  const { data, error, loading, refetch } = useQuery<
    { eventsPayload: Page<Event> },
    { params: string }
  >(GET_EVENTS({ withCounts }), {
    variables: {
      params: `${idParam}${startParam}${countParam}${
        fields ? `&fields=${fields}` : ""
      }${eventWhiteList ? `&event_types=${eventWhiteList.join(",")}` : ""}${
        eventBlacklist ? `&exclude_event_types=${eventBlacklist.join(",")}` : ""
      }${withCountsParam}`,
    },
  });

  const payload = data?.eventsPayload;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, payload, { error, refetch }] as const;
};

export const useGetAccountEvents = ({
  count,
  eventBlacklist,
  eventWhiteList,
  fields,
  id,
  start,
  withCounts,
}: {
  count?: number;
  eventBlacklist?: Array<number>;
  eventWhiteList?: Array<number>;
  fields?: string;
  id: number;
  start?: number;
  withCounts?: boolean;
}) => {
  return useGetEvents({
    idParamName: "account_id",
    idParamValue: id,
    start,
    count,
    eventWhiteList,
    eventBlacklist,
    fields,
    withCounts,
  });
};

export const useGetCompanyEvents = ({
  count,
  eventBlacklist,
  eventWhiteList,
  fields,
  id,
  start,
}: {
  count?: number;
  eventBlacklist?: Array<number>;
  eventWhiteList?: Array<number>;
  fields?: string;
  id: number;
  start?: number;
}) => {
  return useGetEvents({
    idParamName: "company_id",
    idParamValue: id,
    start,
    count,
    eventWhiteList,
    eventBlacklist,
    fields,
  });
};

export const useGetCareseekerEvents = ({
  count,
  eventBlacklist,
  eventWhiteList,
  fields,
  id,
  start,
  withCounts,
}: {
  count?: number;
  eventBlacklist?: Array<number>;
  eventWhiteList?: Array<number>;
  fields: string;
  id: number;
  start?: number;
  withCounts?: boolean;
}) => {
  return useGetEvents({
    idParamName: "careseeker_id",
    idParamValue: id,
    start,
    count,
    eventWhiteList,
    eventBlacklist,
    fields,
    withCounts,
  });
};

export const useGetCareproviderEvents = ({
  count,
  eventBlacklist,
  eventWhiteList,
  fields,
  id,
  start,
  withCounts,
}: {
  count?: number;
  eventBlacklist?: Array<number>;
  eventWhiteList?: Array<number>;
  fields: string;
  id: number;
  start?: number;
  withCounts?: boolean;
}) => {
  return useGetEvents({
    idParamName: "careprovider_id",
    idParamValue: id,
    start,
    count,
    eventWhiteList,
    eventBlacklist,
    fields,
    withCounts,
  });
};

export function useGetAuctionRequestEvents({
  id,
  withEventLastSeens,
}: {
  id: number;
  withEventLastSeens?: boolean;
}) {
  const { query, variables } = getAuctionRequestEventQuery({
    auctionRequestId: id,
    withEventLastSeens,
  });

  const { data, error, loading, refetch } = useQuery<
    {
      eventsPayload: {
        events: Array<Event>;
        remaining: number;
        total: number;
      };
    },
    { params: string }
  >(query, { variables, fetchPolicy: "network-only" });

  const payload = data?.eventsPayload;
  const queryProgress = getQueryProgress(loading, error);
  return [
    queryProgress,
    payload,
    { error, refetch, querySignature: { query, variables } },
  ] as const;
}

export function useGetPatientEvents({
  auctionId,
  count,
  eventIds,
  excludedEventIds,
  patientId,
  reverse = false,
  start,
  withEventLastSeens,
}: {
  auctionId: number;
  count?: number;
  eventIds?: Array<number>;
  excludedEventIds?: Array<number>;
  patientId: number;
  reverse?: boolean;
  start?: number;
  withEventLastSeens?: boolean;
}) {
  const { query, variables } = getPatientEventQuery({
    patientId,
    auctionId,
    start,
    count,
    eventWhiteList: eventIds,
    eventBlacklist: excludedEventIds,
    reverse,
    withEventLastSeens,
  });

  const { data, error, loading, refetch } = useQuery<
    {
      eventsPayload: {
        events: Array<Event>;
        remaining: number;
        total: number;
      };
    },
    { params: string }
  >(query, { variables, fetchPolicy: "network-only" });

  const payload = data?.eventsPayload;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, payload, { error, refetch }] as const;
}

export function useLazyGetPatientEvents({
  count,
  eventIds,
  excludedEventIds,
  reverse = false,
  start,
  withEventLastSeens,
}: {
  count?: number;
  eventIds?: Array<number>;
  excludedEventIds?: Array<number>;
  reverse?: boolean;
  start?: number;
  withEventLastSeens?: boolean;
}) {
  const [getEvents] = useLazyQuery<{
    eventsPayload: {
      events: Array<Event>;
      remaining: number;
      total: number;
    };
  }>(GET_EVENTS());

  function getPatientEvents({
    auctionId,
    patientId,
  }: {
    auctionId: number;
    patientId: number;
  }) {
    const { variables } = getPatientEventQuery({
      patientId,
      auctionId,
      start,
      count,
      eventWhiteList: eventIds,
      eventBlacklist: excludedEventIds,
      reverse,
      withEventLastSeens,
    });
    return getEvents(variables);
  }

  return getPatientEvents;
}

export function useGetAuction(
  id: number | null | undefined,
  options: QueryHookOptions<
    { auction: Auction },
    { auctionId: number | null | undefined }
  > = {},
) {
  const encryptionContext = useApolloEncryptionContext();
  const { data, error, loading, refetch } = useQuery<
    { auction: Auction },
    { auctionId: number | null | undefined }
  >(GET_AUCTION, {
    variables: { auctionId: id },
    context: { encryptionContext },
    fetchPolicy: "network-only",
    ...options,
  });

  const auction = data?.auction;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, auction, { error, refetch }] as const;
}

export function useLazyGetAuction() {
  const encryptionContext = useApolloEncryptionContext();
  const [getAuction] = useLazyQuery<
    { auction: Auction },
    { auctionId: number | null | undefined }
  >(GET_AUCTION, {
    fetchPolicy: "network-only",
    encryptionContext,
  });

  return (id: number | null | undefined) => getAuction({ auctionId: id });
}

export function useGetSenderReceiverNote({
  careproviderId,
  careseekerId,
}: {
  careproviderId: number | null | undefined;
  careseekerId: number | null | undefined;
}) {
  const { data, error, loading, refetch } = useQuery<
    { senderReceiverNote: SenderReceiverNote },
    {
      careproviderId: number | null | undefined;
      careseekerId: number | null | undefined;
    }
  >(GET_SENDER_RECEIVER_NOTE, {
    variables: { careseekerId, careproviderId },
  });

  const queryProgress = getQueryProgress(loading, error);
  const payload = data?.senderReceiverNote;

  return [queryProgress, payload, { error, refetch }] as const;
}

export function useLazyGetCareproviderNotifications() {
  const encryptionContext = useApolloEncryptionContext({ decrypt: true });
  const [getNotifications, queryProgress] = useLazyQuery<{
    careproviderNotifications: {
      events: NotificationEvent[];
      remaining: number;
    };
  }>(GET_CAREPROVIDER_NOTIFICATIONS, {
    encryptionContext,
  });

  const getPaginatedNotifications = useCallback(
    async ({ count = 15, start = 0 }: { count: number; start: number }) => {
      const { data } = await getNotifications({ start, count });

      return [queryProgress, data?.careproviderNotifications] as const;
    },
    [getNotifications],
  );

  return getPaginatedNotifications;
}

export function useGetCareproviderFileList({
  careproviderId,
  careproviderToken,
}: {
  careproviderId: number;
  careproviderToken?: string;
}) {
  const { data, error, loading, refetch } = useQuery<
    { getFiles: CareproviderFiles },
    { careproviderId: number; params: string }
  >(GET_CAREPROVIDER_FILES, {
    variables: {
      careproviderId,
      params: careproviderToken ? `?token=${careproviderToken}` : "",
    },
  });

  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, data?.getFiles, { error, refetch }] as const;
}

export function useLazyGetCareprovidersInfo({
  accountId,
}: {
  accountId: number;
}) {
  const [getLazyCareprovidersInfo] = useLazyQuery<{
    getCareproviderInfo: {
      careprovider_info: {
        [id: number]: {
          requests_in_new_tab: boolean;
        };
      };
    };
  }>(GET_CAREPROVIDER_INFO, { fetchPolicy: "network-only" });

  const getCareprovidersInfo = async () => {
    try {
      const { data } = await getLazyCareprovidersInfo({ id: accountId });
      return data?.getCareproviderInfo?.careprovider_info;
    } catch (err) {
      console.error(
        `error fetching careproviderinfo for account ${accountId}: ${err}`,
      );
      return [];
    }
  };

  return getCareprovidersInfo;
}

export function useGetCareproviderDPAFileLazy({
  careproviderId,
}: {
  careproviderId: number;
}) {
  return useLazyQuery<
    { file: { apistring: string | undefined } },
    { careproviderId: number }
  >(GET_CAREPROVIDER_DPA, {
    variables: { careproviderId },
    fetchPolicy: "network-only",
  });
}

export function useGetUserManagementFileLazy({
  id,
}: GetUserManagmentFileParams) {
  return useLazyQuery<
    { file: { url: string | undefined } },
    GetUserManagmentFileParams
  >(GET_SENDER_USER_MANAGEMENT_FILE, {
    variables: { id },
    fetchPolicy: "network-only",
  });
}

export const useGetFilesQuery = ({ params }: { params: FileParams }) => {
  const [progress, data, rest] = useGenericQuery<
    { files: Filev2[] | undefined },
    { params: FileParams }
  >(GET_FILES, {
    variables: { params },
    fetchPolicy: getFetchPolicy("network-only"),
  });

  return [progress, data?.files, rest] as const;
};

export function useGetSelectableCareproviders({
  fileId,
}: {
  fileId: Filev2["id"];
}) {
  const { data, error, loading, refetch } = useQuery<
    { careproviders: SelectableCareprovider[] },
    { id: number }
  >(GET_SELECTABLE_CAREPROVIDERS, {
    variables: { id: fileId },
    fetchPolicy: "network-only",
    skip: !fileId,
  });
  const queryProgress = getQueryProgress(loading, error);
  const payload = data?.careproviders;

  return [queryProgress, payload, { error, refetch }] as const;
}

export function useGetFilesLazyQuery({ params }: { params: FileParams }) {
  return useLazyQuery<{ files: Filev2[] | undefined }, { params: FileParams }>(
    GET_FILES,
    {
      variables: { params },
      fetchPolicy: "network-only",
    },
  );
}

export function useGetReverseSearches({
  careproviderId,
}: {
  careproviderId: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { reverseSearch: ReverseSearchResult },
    { careproviderId: number }
  >(GET_CAREPROVIDER_REVERSE_SEARCHES, {
    variables: { careproviderId },
  });
  const queryProgress = getQueryProgress(loading, error);
  const payload = data?.reverseSearch;

  return [queryProgress, payload, { error, refetch }] as const;
}

export function useGetBundleLink({ configId }: { configId?: number }) {
  const { data, error, loading, refetch } = useQuery<
    { link: { apistring: string } },
    { configId: number | undefined }
  >(GET_ONPREMISE_BUNDLE_LINK, {
    variables: { configId },
    fetchPolicy: "network-only",
  });
  const queryProgress = getQueryProgress(loading, error);
  const bundleDownloadLink = data?.link.apistring;

  return [queryProgress, bundleDownloadLink, { error, refetch }] as const;
}

export function useGetBundleLinkForCareseeker({
  careseekerId,
}: {
  careseekerId?: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { link: { apistring: string } },
    { careseekerId?: number }
  >(GET_ONPREMISE_BUNDLE_LINK_CARESEEKER, {
    variables: { careseekerId },
    fetchPolicy: "network-only",
  });
  const queryProgress = getQueryProgress(loading, error);
  const bundleDownloadLink = data?.link.apistring;

  return [queryProgress, bundleDownloadLink, { error, refetch }] as const;
}

export function useGetStaticSearchCandidates({
  auctionId,
}: {
  auctionId: number;
}) {
  return useLazyQuery<{ searchCandidates: Candidates }, { auctionId: number }>(
    GET_STATIC_SEARCH_CANDIDATES,
    {
      variables: { auctionId },
    },
  );
}

export const useGetCareproviderWarnings = ({
  auctionId,
  careproviderId,
}: {
  auctionId: number;
  careproviderId?: number;
}) => {
  const { data, error, loading, refetch } = useQuery<
    { careproviderWarnings: WarningsPayload | undefined },
    {
      auctionId: number;
      careproviderId?: number;
    }
  >(GET_CAREPROVIDER_WARNINGS, {
    variables: { careproviderId, auctionId },
    fetchPolicy: "no-cache",
    skip: !careproviderId || !auctionId,
  });

  const queryProgress = careproviderId
    ? getQueryProgress(loading, error)
    : QUERY_PROGRESS_NOT_STARTED;

  return [
    queryProgress,
    data?.careproviderWarnings,
    { error, refetch },
  ] as const;
};

export function useGetProviderPatientRequest(careproviderId: number) {
  const [getProviderPatientRequest] = useLazyQuery<
    { providerPatientRequest: { id: number } },
    { careproviderId: number; patientId: number }
  >(GET_CAREPROVIDER_PATIENT_REQUEST);

  return async (patientId: number) => {
    const { data } = await getProviderPatientRequest({
      patientId,
      careproviderId,
    });
    return data?.providerPatientRequest?.id;
  };
}

const INITIAL_TABLE_PAGE_STATE: TablePageState = {
  page: 1,
  count: SEARCH_TABLE_ROW_COUNT_10,
  sortBy: SEARCH_TABLE_SORTING_DEFAULT,
};

const getSortingTableSortingParam = ({
  sortValue,
  table,
}: {
  sortValue: TableSortValue;
  table: SearchTable;
}) => {
  if (!sortValue || sortValue === SEARCH_TABLE_SORTING_DEFAULT) return "";

  const sortParam = SORTING_MAP?.[sortValue];
  return `&${table}_sort=${sortParam}`;
};

export const getSearchMergeTableQuery = ({
  pageState = INITIAL_TABLE_PAGE_STATE,
  table,
}: {
  pageState?: TablePageState;
  table: SearchTable;
}) => {
  const pageToUse = pageState.page - 1;

  if (
    // Tables that are not paginated
    ([SEARCH_TABLE_VALIDATED, SEARCH_TABLE_ACCEPTED] as SearchTable[]).includes(
      table,
    )
  )
    return table;

  const sortParam = getSortingTableSortingParam({
    sortValue: pageState.sortBy,
    table,
  });

  return `${table}_start=${pageToUse * pageState.count}&${table}_count=${
    pageState.count
  }${sortParam}`;
};

export const getSearchMergeParams = ({
  pagination,
}: {
  pagination: SearchTablePagination | null;
}) => {
  if (!pagination) return "";

  let queryString = "";

  Object.entries(pagination).forEach(([table, pageState], index) => {
    queryString += `${index === 0 ? "" : "&"}${getSearchMergeTableQuery({
      table: table as SearchTable,
      pageState,
    })}`;
  });

  return queryString;
};

const searchMergePayloadMapping: Record<SearchTable, TablePayload> = {
  [SEARCH_TABLE_ACCEPTED]: TABLE_PAYLOAD_ACCEPTED,
  [SEARCH_TABLE_CONTACTED]: TABLE_PAYLOAD_CONTACTED,
  [SEARCH_TABLE_DECLINED]: TABLE_PAYLOAD_DECLINED,
  [SEARCH_TABLE_FILTERED]: TABLE_PAYLOAD_FILTERED,
  [SEARCH_TABLE_POTENTIAL]: TABLE_PAYLOAD_POTENTIAL,
  [SEARCH_TABLE_REJECTED]: TABLE_PAYLOAD_REJECTED,
  [SEARCH_TABLE_VALIDATED]: TABLE_PAYLOAD_VALIDATED,
};

const fillTableCaches = ({
  auctionId,
  client,
  data,
  pagination,
}: {
  auctionId: number;
  client: ApolloClient<ApolloCacheData>;
  data:
    | {
        searchMergeRequests: SearchMergePayload;
      }
    | undefined;
  pagination: SearchTablePagination | null;
}) => {
  if (!data || !pagination) return;

  const tableTotals: SearchTableTotals & { __typename: string } = {
    __typename: "TableTotals",
  };

  Object.entries(pagination).forEach(([table, pageState]) => {
    const tablePayload = searchMergePayloadMapping[table as SearchTable];
    const queryParams = getSearchMergeTableQuery({
      pageState,
      table: table as SearchTable,
    });
    const tableData = data?.searchMergeRequests?.[tablePayload];

    tableTotals[tablePayload] =
      data?.searchMergeRequests?.[tablePayload]?.total ?? 0;

    if (
      tablePayload === TABLE_PAYLOAD_VALIDATED &&
      data?.searchMergeRequests?.[tablePayload]?.request?.id
    ) {
      tableTotals[tablePayload] = 1;
    }
    client.writeQuery({
      query: GET_SEARCH_MERGE_REQUESTS({
        [table as SearchTable]: true,
      }),
      variables: {
        auctionId,
        queryParams,
      },
      data: {
        searchMergeRequests: {
          [tablePayload]: tableData,
          __typename: "SearchMerge",
        } as SearchMergePayload & { __typename: string },
      },
    });
  });

  client.writeQuery({
    query: GET_SEARCH_MERGE_TOTALS,
    variables: {
      auctionId,
    },
    data: {
      searchMergeTableTotals: tableTotals,
    },
  });
};

const getSearchTableStorageKey = ({
  auctionId,
  table,
}: {
  auctionId: number;
  table: SearchTable;
}) => `${auctionId}-${table}-search-merge-pagination`;

export const getSearchTablePagination = (
  auctionId: number,
): SearchTablePagination => {
  const pagination = {} as SearchTablePagination;
  SEARCH_TABLE_TABLES.forEach((table) => {
    const storedTablePage = sessionStorage.getItem(
      getSearchTableStorageKey({ table, auctionId }),
    );

    pagination[table] = storedTablePage
      ? JSON.parse(storedTablePage)
      : INITIAL_TABLE_PAGE_STATE;
  });

  return pagination;
};

export function useGetPrintableSearchMergeRequests({
  auctionId,
  printSections,
}: {
  auctionId: number;
  printSections: string | null;
}): [SearchMergePayload | undefined] {
  const fragments: Record<SearchTable, boolean> = {
    [SEARCH_TABLE_ACCEPTED]: true,
    [SEARCH_TABLE_CONTACTED]: true,
    [SEARCH_TABLE_DECLINED]: true,
    [SEARCH_TABLE_FILTERED]: true,
    [SEARCH_TABLE_POTENTIAL]: true,
    [SEARCH_TABLE_REJECTED]: true,
    [SEARCH_TABLE_VALIDATED]: true,
  };

  const { data } = useQuery<{
    searchMergeRequests: SearchMergePayload;
  }>(GET_SEARCH_MERGE_REQUESTS(fragments), {
    variables: {
      auctionId,
      queryParams: `${SEARCH_MERGE_PRINT_SECTIONS_PARAM}=${printSections}`,
    },
    fetchPolicy: "network-only",
    skip: auctionId === -1 || !printSections?.length,
  });

  return [data?.searchMergeRequests];
}

export function useGetSearchMergeMapRequests({
  auctionId,
}: {
  auctionId: number;
}) {
  const { data } = useQuery<
    { requests: AuctionRequest[] },
    { auctionId: number }
  >(GET_SEARCH_MERGE_MAP_REQUESTS, {
    variables: { auctionId },
    fetchPolicy: "network-only",
  });

  return [data?.requests] as const;
}

export function usePreloadSearchMergeRequests({
  auctionId,
}: {
  auctionId: number;
}) {
  const client = useApolloClient();
  const translations = useTranslations();
  const pagination = getSearchTablePagination(auctionId);
  const [searchMergeContext, setSearchMergeContext] = useSafeState<
    SearchMergePayload["context"]
  >({ search_merge_banners: [], search_merge_status: undefined });
  const fetchedRef = useRef<boolean>(false);
  const toast = useToast();

  const fragments = {
    [SEARCH_TABLE_ACCEPTED]: true,
    [SEARCH_TABLE_CONTACTED]: true,
    [SEARCH_TABLE_DECLINED]: true,
    [SEARCH_TABLE_FILTERED]: true,
    [SEARCH_TABLE_POTENTIAL]: true,
    [SEARCH_TABLE_REJECTED]: true,
    [SEARCH_TABLE_VALIDATED]: true,
    context: true,
  };

  const params = getSearchMergeParams({ pagination });

  const handleSearchMergeData = ({
    data,
  }: {
    data: {
      searchMergeRequests: SearchMergePayload;
    };
  }) => {
    if (!data?.searchMergeRequests) {
      return;
    }

    if (data.searchMergeRequests.context != null) {
      setSearchMergeContext(data.searchMergeRequests.context);
    }

    fillTableCaches({ data, client, pagination, auctionId });
  };

  const { error, loading, refetch } = useQuery<
    { searchMergeRequests: SearchMergePayload },
    { auctionId: number; queryParams: string }
  >(GET_SEARCH_MERGE_REQUESTS(fragments), {
    variables: {
      auctionId,
      queryParams: params,
    },
    skip: fetchedRef.current,
    // network-only causes rerenders
    fetchPolicy: getFetchPolicy("no-cache"),
    onCompleted: (data) => {
      handleSearchMergeData({ data });
    },
    onError: (err) => {
      console.error(
        `error fetching search merge results: ${getErrorMessage(err)}`,
      );
      toast({
        message: translations.auctionRequest.tryAgain,
        color: "danger",
      });
    },
  });

  const refreshData = async () => {
    try {
      fetchedRef.current = true;
      const refetched = await refetch({
        auctionId,
        queryParams: params,
      });
      handleSearchMergeData({ data: refetched.data });
    } catch (err) {
      console.error(
        `error refetching search merge results: ${getErrorMessage(err)}`,
        {
          err,
        },
      );
      toast({
        message: translations.auctionRequest.tryAgain,
        color: "danger",
      });
    }
  };

  useHandleAutoRefreshComponent(auctionId, refreshData);

  return [
    searchMergeContext,
    getQueryProgress(loading, error),
    refreshData,
  ] as const;
}

export const useGetSearchMergeTableTotals = ({
  auctionId,
}: {
  auctionId: number;
}) => {
  const { data, loading } = useQuery<
    { searchMergeTableTotals: SearchTableTotals & { __typename?: string } },
    { auctionId: number }
  >(GET_SEARCH_MERGE_TOTALS, {
    variables: { auctionId },
    fetchPolicy: getFetchPolicy("cache-only"),
  });

  return [loading, data?.searchMergeTableTotals] as const;
};

const getInitialTablePageState = ({
  storageKey,
}: {
  storageKey: string;
}): TablePageState => {
  const localStorageTablePageState = sessionStorage.getItem(storageKey);

  return localStorageTablePageState
    ? JSON.parse(localStorageTablePageState)
    : INITIAL_TABLE_PAGE_STATE;
};

export function useGetSearchMergeTable<
  TableData extends
    | AcceptedTablePayload
    | ContactedTablePayload
    | DeclinedTablePayload
    | FilteredTablePayload
    | PotentialReceiversTablePayload
    | RejectedTablePayload
    | ValidatedTablePayload,
>({ auctionId, table }: { auctionId: number; table: SearchTable }) {
  const storageKey = getSearchTableStorageKey({ auctionId, table });
  const shouldFetchFromCache = useRef<boolean>(true);
  const [currentTablePageState, setCurrentTablePageState] = useState(
    getInitialTablePageState({ storageKey }),
  );

  const paginationQueryString = getSearchMergeTableQuery({
    pageState: currentTablePageState,
    table,
  });

  const { data, error, loading } = useQuery<
    { searchMergeRequests: SearchMergePayload },
    { auctionId: number; queryParams: string }
  >(
    GET_SEARCH_MERGE_REQUESTS({
      [table]: true,
    }),
    {
      variables: {
        auctionId,
        queryParams: paginationQueryString,
      },
      fetchPolicy: getFetchPolicy(
        shouldFetchFromCache.current ? "cache-only" : "cache-first",
      ),
    },
  );

  const tablePayload = searchMergePayloadMapping[table as SearchTable];

  const tableData = data?.searchMergeRequests?.[tablePayload] as
    | TableData
    | undefined;

  useEffect(() => {
    if (!tableData?.total) return;
    const totalPages = Math.ceil(tableData.total / currentTablePageState.count);

    // Handle if the total number of pages is less that than available in the state
    if (currentTablePageState.page > totalPages) {
      const pageState: TablePageState = {
        page: 1,
        count: currentTablePageState.count,
        sortBy: currentTablePageState.sortBy,
      };
      sessionStorage.setItem(storageKey, JSON.stringify(pageState));
      shouldFetchFromCache.current = false;
      setCurrentTablePageState(pageState);
    }
  }, [tableData?.total]);

  return {
    tableData,
    queryProgress: getQueryProgress(loading, error),
    currentTablePageState,
    handlePagination: ({
      count,
      page,
      sortBy,
    }: {
      count: TableCount;
      page: number;
      sortBy: TableSortValue;
    }) => {
      shouldFetchFromCache.current = false;
      setCurrentTablePageState(() => {
        const pageState: TablePageState = {
          page,
          count,
          sortBy,
        };
        sessionStorage.setItem(storageKey, JSON.stringify(pageState));

        return pageState;
      });
    },
  };
}

export function useGetProductTour({
  name,
  skip,
}: {
  name: ProductTourKey;
  skip?: boolean;
}) {
  const { data, error, loading, refetch } = useQuery<
    { productTour: ProductTourCompletion },
    { name: ProductTourKey }
  >(GET_PRODUCT_TOUR, {
    variables: { name },
    fetchPolicy: "no-cache",
    skip,
  });
  const queryProgress = getQueryProgress(loading, error);
  const payload = data?.productTour;

  return [queryProgress, payload, { error, refetch }] as const;
}

export function useGetVersions({
  ...options
}: QueryHookOptions<{ versions?: Version[] }> = {}) {
  const { data, error, loading, refetch, startPolling, stopPolling } =
    useQuery<{ versions?: Version[] }>(GET_VERSIONS, {
      ...options,
      fetchPolicy: getFetchPolicy(options.fetchPolicy),
    });

  usePolling({ shouldPoll: true, interval: 60000, startPolling, stopPolling });

  const versions = data?.versions;

  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, versions, { error, refetch }] as const;
}

export const useGetEncryptionJobsQuery = ({
  careseekerId,
  skip,
}: {
  careseekerId: number;
  skip?: boolean;
}) => {
  const { data, error, loading, refetch, startPolling, stopPolling } = useQuery<
    { encryptionJobs?: EncryptionJob[] | null },
    { careseekerId: number }
  >(GET_ENCRYPTION_JOBS, {
    variables: { careseekerId },
    skip,
  });
  usePolling({ shouldPoll: !skip, startPolling, stopPolling });

  return [
    getQueryProgress(loading, error),
    data?.encryptionJobs,
    { error, refetch },
  ] as const;
};

export function useGetProviderSearchPatients({ skip }: { skip: boolean }) {
  const { data, error, loading, refetch } = useQuery<{
    patients: ProviderSearchPatients;
  }>(GET_PROVIDER_SEARCH_PATIENTS, { skip });

  const patients = data?.patients;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, patients, { error, refetch }] as const;
}

const useGetProviderSearchDetailsQueryParams = ({
  filters,
}: {
  filters: ProviderSearchFilters;
}): string => {
  const queryParams: string[] = [];
  if (filters?.start_date) {
    queryParams.push(`${USER_FILTER_START_DATE}=${filters.start_date}`);
  }
  if (filters?.zipcode?.value) {
    queryParams.push(
      `${USER_FILTER_TREATMENT_LOCATION}=${filters.zipcode.value}`,
    );
  }

  return queryParams.join("&");
};

export function useGetProviderSearchConsultant({ id }: { id: number }) {
  const { filters } = useProviderSearchFilterContext();
  const queryParams = useGetProviderSearchDetailsQueryParams({ filters });

  const { data, error, loading, refetch } = useQuery<
    { consultant: ProviderSearchProvider },
    { id: number; queryParams: string }
  >(GET_PROVIDER_SEARCH_CONSULTANT, {
    variables: { id, queryParams },
    skip: !filters.zipcode?.value,
  });

  const consultant = data?.consultant;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, consultant, { error, refetch }] as const;
}

export function useGetProviderSearchCareprovider({ id }: { id: number }) {
  const { filters } = useProviderSearchFilterContext();
  const queryParams = useGetProviderSearchDetailsQueryParams({ filters });

  const { data, error, loading, refetch } = useQuery<
    { providerSearchProvider: ProviderSearchProvider },
    { id: number; queryParams: string }
  >(GET_PROVIDER_SEARCH_PROVIDER, {
    variables: { id, queryParams },
    skip: !filters.zipcode?.value,
  });

  const careprovider = data?.providerSearchProvider;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, careprovider, { error, refetch }] as const;
}

export function useGetProviderSearchOverview({
  fetchPolicy = "network-only",
  nextFetchPolicy,
  patientId,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  patientId: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    {
      providerSearchRequestOverview: ProviderSearchRequestsOverview;
    },
    { patientId: number }
  >(GET_PROVIDER_SEARCH_REQUEST_OVERVIEW, {
    variables: { patientId },
    fetchPolicy,
    nextFetchPolicy,
  });

  const requestOverview = data?.providerSearchRequestOverview;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, requestOverview, { error, refetch }] as const;
}

export function useLazyProviderSearchActivation() {
  const [activateProviderSearchAccount] = useLazyQuery<
    { activateProviderSearch: ProviderSearchActivation },
    { token: string }
  >(ACTIVATE_PROVIDER_SEARCH_ACCOUNT);

  return async (token: string) => {
    const { data } = await activateProviderSearchAccount({ token });
    return data?.activateProviderSearch;
  };
}

export function useLazyProviderSearchLoginActivation() {
  const [activateProviderSearchAccount] = useLazyQuery<
    { loginProviderSearch: ProviderSearchActivation },
    { token: string }
  >(LOGIN_ACTIVATION_PROVIDER_SEARCH_ACCOUNT);

  return async (token: string) => {
    const { data } = await activateProviderSearchAccount({ token });
    return data?.loginProviderSearch;
  };
}

export type LimitedAddress = Pick<
  Location,
  "city" | "latitude" | "longitude" | "zipcode" | "federal_state"
>;

export function useGetAddressFromZipcode({
  onCompleted,
  onError,
  zipcode,
}: QueryHookOptions<
  { address: LimitedAddress },
  { zipcode: string | undefined }
> & {
  zipcode: string | undefined;
}) {
  const { data, error, loading, refetch } = useQuery<
    { address: LimitedAddress },
    { zipcode: string | undefined }
  >(GET_ADDRESS_FROM_ZIPCODE, {
    variables: { zipcode },
    skip: !zipcode || zipcode?.length !== 5,
    onError: onError,
    onCompleted: onCompleted,
  });
  const queryProgress = getQueryProgress(loading, error);
  const address = data?.address;

  return [queryProgress, address, { error, refetch }] as const;
}

export function useLazyGetAddressFromZipcode() {
  const [getAddressFromZipcode] = useLazyQuery<
    { address: LimitedAddress },
    { zipcode?: string }
  >(GET_ADDRESS_FROM_ZIPCODE);
  return async (zipcode?: string): Promise<LimitedAddress | undefined> => {
    try {
      if (!zipcode || zipcode?.length !== 5) return;
      const { data } = await getAddressFromZipcode({ zipcode });
      return data?.address;
    } catch (err) {
      console.error(
        `error fetching address from zipcode: ${zipcode} - ${getErrorMessage(
          err,
        )}`,
      );
    }
  };
}

export function useZipcodeInCareproviderCatchment({
  careproviderId,
  zipcode,
}: {
  careproviderId: number;
  zipcode: string | undefined;
}) {
  const { data, error, loading, refetch } = useQuery<
    { catchment: { inside_catchment: boolean } },
    { careproviderId: number; zipcode: string | undefined }
  >(ZIPCODE_INSIDE_CATCHMENT, {
    variables: { zipcode, careproviderId },
    skip: !zipcode || zipcode?.length !== 5,
  });
  const queryProgress = getQueryProgress(loading, error);
  const isInside = data?.catchment;

  return [queryProgress, isInside, { error, refetch }] as const;
}

export function useGetProviderSearchListing({
  filters,
}: {
  filters: ProviderSearchFilters;
}) {
  const queryParams = toFilterQueryString(filters);

  const { data, error, loading, refetch } = useQuery<
    { providerSearchListing: ProviderSearchListing },
    { queryParams: string }
  >(GET_PROVIDER_SEARCH_LISTING, {
    variables: { queryParams },
    skip: !filters?.zipcode?.value,
  });
  const providerSearchListing = data?.providerSearchListing;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, providerSearchListing, { error, refetch }] as const;
}

export function useGetConsultant({ id }: { id: number }) {
  const { data, error, loading, refetch } = useQuery<
    { consultant: ProviderSearchProvider },
    { id: number }
  >(GET_CONSULTANT, {
    variables: { id },
    skip: !id,
  });

  const consultant = data?.consultant;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, consultant, { error, refetch }] as const;
}

export function useGetConsultantEvents({
  count,
  id,
  page,
}: {
  count: number;
  id: number;
  page: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { eventPage: ConsultantEventPage },
    { id: number; queryParams: string }
  >(GET_CONSULTANT_EVENTS, {
    variables: {
      id,
      queryParams: `start=${count * (page - 1)}&count=${count}`,
    },
    skip: !id,
  });

  const consultantEvents = data?.eventPage;
  const queryProgress = getQueryProgress(loading, error);

  return [queryProgress, consultantEvents, { error, refetch }] as const;
}

export function useGetConsultantFileList({
  consultantId,
}: {
  consultantId: number;
}) {
  const { data, error, loading, refetch } = useQuery<
    { getFiles: Pick<CareproviderFiles, "images"> },
    { consultantId: number }
  >(GET_CONSULTANT_FILES, {
    variables: {
      consultantId,
    },
  });

  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, data?.getFiles, { error, refetch }] as const;
}

export function useGetEmailTranslations(options: QueryHookOptions = {}) {
  const { data, error, loading, refetch } = useQuery<{
    translations: EmailTranslation[];
  }>(GET_EMAIL_TRANSLATIONS, options);

  const translations = data?.translations;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, translations, { error, refetch }] as const;
}

export function useGetEmailTranslation(
  id: string,
  options: QueryHookOptions<
    { translation: EmailTranslation },
    { id: string }
  > = {},
) {
  const { data, error, loading, refetch } = useQuery<
    { translation: EmailTranslation },
    { id: string }
  >(GET_EMAIL_TRANSLATION, {
    variables: { id },
    ...options,
  });

  const translation = data?.translation;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, translation, { error, refetch }] as const;
}

export function useGetTransitionalCare({
  auctionId,
  patientId,
  options = {},
}: {
  auctionId: number;
  options?: QueryHookOptions<
    { auction: { transitional_care?: TransitionalCare } },
    { auctionId: number; patientId: number }
  >;
  patientId: number;
}) {
  const encryptionContext = useApolloEncryptionContext();
  const { data, error, loading, refetch } = useQuery<
    { auction: { transitional_care?: TransitionalCare } },
    { auctionId: number; patientId: number }
  >(GET_TRANSITIONAL_CARE, {
    variables: { auctionId, patientId },
    context: { encryptionContext },
    ...options,
  });

  const transitionalCare = data?.auction?.transitional_care;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, transitionalCare, { error, refetch }] as const;
}

export function useGetTransitionalCareRequests({
  auctionId,
}: {
  auctionId: number;
}) {
  const [lazyGetRequests, queryProgress] = useLazyQuery<{
    transitionalCareRequests: TransitionalCareImportedAuctionRequest[];
  }>(GET_TRANSITIONAL_CARE_REQUESTS, {
    variables: { auctionId },
    fetchPolicy: "no-cache",
  });

  const getRequests = async () => {
    try {
      const { data } = await lazyGetRequests();
      return data?.transitionalCareRequests;
    } catch (err) {
      console.error(
        `error importing transitional care requests - ${getErrorMessage(err)}`,
      );
      return null;
    }
  };

  return [queryProgress, getRequests] as const;
}

export function useGetRehabForms({
  auctionId,
  patientId,
  options = {},
}: {
  auctionId: number;
  options?: QueryHookOptions<
    { auction: { rehab_forms?: RehabForms } },
    { auctionId: number; patientId: number }
  >;
  patientId: number;
}) {
  const encryptionContext = useApolloEncryptionContext();
  const { data, error, loading, refetch } = useQuery<
    { auction: { rehab_forms?: RehabForms } },
    { auctionId: number; patientId: number }
  >(GET_REHAB_FORMS, {
    variables: { auctionId, patientId },
    context: { encryptionContext },
    ...options,
  });

  const rehabForms = data?.auction?.rehab_forms;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, rehabForms, { error, refetch }] as const;
}

export function useGetBedrockPlaygroundData(props?: {
  options?: QueryHookOptions<{
    bedrockPlayground: BedrockPlaygroundPage;
  }>;
}) {
  const { data, error, loading, refetch } = useQuery<{
    bedrockPlayground: BedrockPlaygroundPage;
  }>(GET_BEDROCK_PLAYGROUND, props?.options);

  const bedrockPlayground = data?.bedrockPlayground;
  const queryProgress = getQueryProgress(loading, error);
  return [queryProgress, bedrockPlayground, { error, refetch }] as const;
}

export function useSealdGroupSessionsCheck({
  sealdId,
}: {
  sealdId: string | undefined;
}) {
  return useGenericQuery<
    { sealdGroupSessions: GroupSessionsResponse },
    { sealdId: string | undefined }
  >(SEALD_GROUP_SESSIONS_CHECK, {
    variables: { sealdId },
    fetchPolicy: "network-only",
  });
}
