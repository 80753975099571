import { FEDERAL_STATES, TRACK_EVENTS } from "core/consts";
import Tile, { TILETYPE } from "ds_legacy/components/TileButton";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, responsiveCSS } from "ds_legacy/materials/metrics";
import ZipcodesSelect from "dsl/atoms/ZipcodesSelect";
import { USER_FILTER_TREATMENT_LOCATION } from "dsl/organisms/Filters/consts";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import OnboardingCard, { PAGES, PageProps, testIds } from "../common";

const TileWrapper = styled(HorizontalLayout)`
  margin: ${margin(9, 0)};
  width: 100%;
  ${responsiveCSS({
    mobile: [`justify-content: center`],
    tablet: [`justify-content: center`],
    desktop: [`justify-content: flex-start`],
  })};
`;

export default function Location({
  filters,
  navigateToPage,
  setFilters,
  translations,
}: PageProps) {
  const { trackEvent } = useTracking();
  return (
    <OnboardingCard
      title={translations.bToC.onboardingScreens.titleWhereToSearch}
      navigateToPage={navigateToPage}
      data-testid={testIds.location}
    >
      <VerticalLayout margin={margin(2, 1)}>
        <div data-testid={testIds.locationInput}>
          <ZipcodesSelect
            required
            withPortal
            federalState={FEDERAL_STATES.NIEDERSACHSEN}
            noOptionsText={translations.bToC.noResultsWithState({
              state: FEDERAL_STATES.NIEDERSACHSEN,
            })}
            elementName="location"
            placeholder={translations.bToC.filterDrawer.searchRadiusPlaceholder}
            onChange={(value) => {
              if (value)
                return setFilters({
                  type: USER_FILTER_TREATMENT_LOCATION,
                  value,
                });
            }}
          />
        </div>
        <TileWrapper aligned>
          <Tile
            label={translations.bToC.filterDrawer.showResults}
            tileType={TILETYPE.B2C}
            onClick={() => {
              trackEvent({
                name: TRACK_EVENTS.B2C_ONBOARDING_SHOW_RESULTS_BUTTON_CLICKED,
              });
              trackEvent({
                name: TRACK_EVENTS.DASHBOARD_FILTERS,
                search_target: filters.zipcode?.id,
              });
              navigateToPage(PAGES.LOADING);
            }}
          />
        </TileWrapper>
      </VerticalLayout>
    </OnboardingCard>
  );
}
