import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  FormHelperTextProps,
  MenuItem,
  TextField,
  useAutocomplete,
} from "@mui/material";
import { DEFAULT_ALGOLIA_QUERY, EMPTY_ALGOLIA_QUERY } from "core/consts";
import { getKey } from "core/model/utils/strings";
import { AlgoliaAnalyticsName, Validation } from "core/types";
import { PlaylistAddIcon } from "ds/icons";
import RSButton from "ds_legacy/components/RSButton";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { getHelperText } from "ds_legacy/components/Validation";
import { Z_INDEX_DRAWER_CONTENT, margin } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14, FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { useAlgoliaSearchClient } from "dsl/hooks/useAlgoliaSearchClient";
import { debounce } from "lodash";
import React, {
  CSSProperties,
  ChangeEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormElement, FormElementProps, isValid } from "react-forms-state";
import { AutocompleteProvided, Hit } from "react-instantsearch-core";
import {
  Configure,
  InstantSearch,
  connectAutoComplete,
} from "react-instantsearch-dom";
import { useTranslations } from "translations";
import { useMedia } from "../ResponsiveMedia";

export type AlgoliaSelectPresenterProps = {
  ListboxProps?: ReturnType<
    ReturnType<typeof useAutocomplete>["getListboxProps"]
  >;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  autoHighlight?: boolean;
  closeMenuOnSelect?: boolean;
  currentRefinement: string;
  customNoResultMessage?: React.ReactNode | null;
  disableLabelShrink?: boolean;
  disableUnderline?: boolean;
  disabled?: boolean;
  elementName?: string;
  errorOverride?: boolean;
  filterSelectedOptions?: boolean;
  hasCustomValidation?: boolean;
  hideError?: boolean;
  hits: Hit<any>[];
  id?: string;
  label?: string;
  maxOptions?: number;
  minCharacterForRefinement?: number;
  multiple?: boolean;
  noOptionsText?: ReactNode;
  onChange: (arg: ToType) => void;
  pasteContext?: { buttonText: string; tooltipText: string };
  placeholder?: string;
  refine: (arg: ToType) => ToType;
  renderHits: (hit: Hit<any>, idx: number) => any;
  required?: boolean;
  searchWithoutTyping?: boolean;
  truncateMenuItems?: boolean;
  useShortValue?: boolean;
  validation?: Validation;
  value?: SelectOption | SelectOption[] | null;
  withPaste?: boolean;
  withPortal?: boolean;
  zIndex?: CSSProperties["zIndex"];
};

const AlgoliaSelectConnectedWithForm = FormElement()<
  FormElementProps &
    Omit<
      AlgoliaSelectPresenterProps,
      "currentRefinement" | "hits" | "onChange" | "refine"
    > & {
      defaultRefinement: string;
    }
>((props) => (
  <AlgoliaSelect
    {...props}
    defaultRefinement={props.value?.label || props.defaultRefinement}
  />
));

export const usePaste = (
  onChange: (normalisedPaste: { label: string; value: string }[]) => void,
) => {
  const [didPaste, setDidPaste] = useState(false);
  useEffect(() => {
    if (didPaste) {
      navigator.clipboard
        .readText()
        .then((clipText: string) => {
          const normalisedPaste = clipText
            .split(",")
            .map((zip: string) => ({ value: zip, label: zip }));

          onChange(normalisedPaste);

          setDidPaste(false);
        })
        .catch(console.error);
    }
  }, [didPaste]);

  return () => setDidPaste(true);
};

const AlgoliaSelectPresenter = ({
  ariaLabel,
  ariaLabelledBy,
  autoHighlight,
  closeMenuOnSelect = true,
  currentRefinement,
  disabled = false,
  disableLabelShrink,
  disableUnderline,
  elementName,
  errorOverride,
  filterSelectedOptions,
  hasCustomValidation,
  hideError,
  hits,
  id,
  label,
  ListboxProps,
  maxOptions,
  minCharacterForRefinement = 0,
  multiple,
  noOptionsText,
  onChange,
  pasteContext,
  placeholder,
  refine,
  renderHits,
  required,
  truncateMenuItems,
  useShortValue = false,
  validation,
  value,
  withPaste,
  withPortal,
  zIndex,
}: AlgoliaSelectPresenterProps) => {
  const [localValue, setLocalValue] = useState(value || multiple ? value : "");
  const [open, setOpen] = useState(false);
  const { isMobile } = useMedia();
  const translations = useTranslations();
  const inputName = `autocomplete_${elementName || "input"}`;

  const hasError = errorOverride || isValid(validation) === false;

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const valueFromEvent = event.target.value;
    if (valueFromEvent.length >= minCharacterForRefinement) {
      refine(valueFromEvent);
      setOpen(true);
    }
  };

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 100), []);

  const paste = usePaste(onChange);

  useEffect(() => {
    if (value == null) {
      setLocalValue(multiple ? [] : "");
    } else {
      setLocalValue(value);
    }
  }, [value]);

  if (localValue == null) return null;

  const isMaxOptions =
    multiple &&
    !!maxOptions &&
    (localValue as SelectOption[]).length >= maxOptions;

  return (
    <>
      {withPaste && pasteContext && (
        <RSButton
          id="paste"
          LeftIcon={PlaylistAddIcon}
          loading="na"
          onClick={paste}
          style={{ margin: margin(0, -1) }}
          tooltip={pasteContext?.tooltipText}
          variant="text"
        >
          {pasteContext.buttonText}
        </RSButton>
      )}
      <Autocomplete
        id={inputName}
        open={
          !!maxOptions && multiple
            ? open && (localValue as SelectOption[]).length < maxOptions
            : open
        }
        onOpen={() => setOpen(!open)}
        onClose={() => setOpen(false)}
        onKeyPress={(e) => {
          isMaxOptions && e.preventDefault();
        }}
        autoHighlight={autoHighlight}
        disableCloseOnSelect={!closeMenuOnSelect}
        disabled={disabled}
        disablePortal={!withPortal}
        filterSelectedOptions={filterSelectedOptions}
        filterOptions={(option) => option} // DEV-11459: to avoid filtering the algolia result
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        ListboxProps={ListboxProps}
        multiple={multiple}
        noOptionsText={noOptionsText}
        onChange={(_, newValue) => {
          if (multiple && !!maxOptions) {
            if (newValue.length <= maxOptions) onChange(newValue);
          } else onChange(newValue);
        }}
        options={hits.map(renderHits)}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={isMaxOptions}
            error={hideError ? false : hasError}
            InputLabelProps={{
              ...params.InputLabelProps,
              style: { fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14 },
              shrink: disableLabelShrink ? false : undefined,
            }}
            helperText={
              hideError
                ? undefined
                : getHelperText({
                    hasCustomValidation: !!hasCustomValidation,
                    translations,
                    validation,
                  })
            }
            inputProps={{
              ...params.inputProps,
              "data-testid": inputName,
              "aria-label": ariaLabel,
              "aria-labelledby": ariaLabelledBy,
              id: id ?? params.inputProps.id,
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14,
              },
              disableUnderline,
            }}
            FormHelperTextProps={
              {
                "data-testid": `${inputName}-form-helper-text`,
              } as FormHelperTextProps
            }
            sx={{
              "& input::placeholder": {
                opacity: 0.6,
              },
            }}
            label={label}
            name={elementName}
            onChange={debouncedHandleSearch}
            placeholder={placeholder}
            required={required}
            value={currentRefinement}
            variant="standard"
          />
        )}
        renderOption={({ onMouseOver: _, ...props }, option, state) => {
          return (
            <MenuItem
              {...props}
              key={getKey(props.id)}
              selected={state.selected}
              data-testid={`option-${option.value}`}
              divider
              sx={{
                fontSize: FONT_SIZE_14,
                whiteSpace: truncateMenuItems ? undefined : "normal",
              }}
            >
              <div
                style={{
                  ...(truncateMenuItems
                    ? { overflow: "hidden", textOverflow: "ellipsis" }
                    : {}),
                }}
              >
                {option.label}
              </div>
            </MenuItem>
          );
        }}
        renderTags={(values, getTagProps: AutocompleteRenderGetTagProps) =>
          values.map((value, index: number) => {
            const { key, ...rest } = getTagProps({ index });
            return (
              <Chip
                key={key}
                {...rest}
                label={useShortValue ? value.value : value.label}
                data-testid={`selected-${value.value}`}
              />
            );
          })
        }
        size="small"
        componentsProps={{
          popper: {
            placement: "bottom",
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
            sx: { zIndex: zIndex || Z_INDEX_DRAWER_CONTENT },
          },
          clearIndicator: { "aria-hidden": "true" },
        }}
        clearText={translations.actions.clear}
        openText={translations.actions.expand}
        closeText={translations.actions.close}
        sx={{
          fontSize: FONT_SIZE_14,
        }}
        value={localValue}
      />
    </>
  );
};

const AlgoliaSelect = connectAutoComplete<
  AlgoliaSelectPresenterProps & AutocompleteProvided
>(AlgoliaSelectPresenter);

export default function AlgoliaSelectDefault({
  algoliaAnalyticsName,
  searchWithoutTyping,
  defaultQuery = searchWithoutTyping
    ? EMPTY_ALGOLIA_QUERY
    : DEFAULT_ALGOLIA_QUERY,
  filters,
  hitsPerPage,
  indexName,
  indexWithEnv,
  onChange,
  sideMutation,
  connected,
  ...props
}: Omit<
  AlgoliaSelectPresenterProps,
  "currentRefinement" | "hits" | "onChange" | "refine"
> & {
  algoliaAnalyticsName: AlgoliaAnalyticsName;
  connected?: boolean;
  defaultQuery?: string;
  elementName: string;
  filters?: string;
  hideError?: boolean;
  hitsPerPage?: number;
  indexName: string;
  indexWithEnv?: boolean;
  onChange?: AlgoliaSelectPresenterProps["onChange"];
  sideMutation?: (newValue: any, mutateElement: any) => void;
}) {
  const { analyticsTags, indexNameWithEnv, searchClient } =
    useAlgoliaSearchClient({ algoliaAnalyticsName, indexName, indexWithEnv });

  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName={indexNameWithEnv}>
        <Configure
          analyticsTags={analyticsTags}
          clickAnalytics
          filters={filters}
          hitsPerPage={hitsPerPage ?? 50}
        />
        {connected ? (
          <AlgoliaSelectConnectedWithForm
            {...props}
            defaultRefinement={defaultQuery}
            sideMutation={sideMutation}
          />
        ) : (
          <AlgoliaSelect
            {...props}
            onChange={onChange || console.log}
            defaultRefinement={defaultQuery}
          />
        )}
      </InstantSearch>
    </div>
  );
}
