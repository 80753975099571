import { TRACK_EVENTS } from "core/consts";
import { PersonIcon, SupervisorAccountIcon } from "ds/icons";
import Tile, { TILETYPE } from "ds_legacy/components/TileButton";
import { useTracking } from "react-tracking";
import OnboardingCard, {
  PageProps,
  PAGES,
  StyledCaption,
  testIds,
} from "../common";

const FOR_MYSELF = "for myself";
const FOR_SOMEONE = "for someone";

export default function Target({ navigateToPage, translations }: PageProps) {
  const { trackEvent } = useTracking();

  const gotoNext = (target: string) => {
    navigateToPage(PAGES.START_DATE);
    trackEvent({
      name: TRACK_EVENTS.DASHBOARD_FILTERS,
      search_target: target,
    });
  };
  return (
    <OnboardingCard
      title={translations.bToC.onboardingScreens.titleForWhom}
      navigateToPage={navigateToPage}
      data-testid={testIds.target}
    >
      <StyledCaption>
        {translations.bToC.onboardingScreens.yourChoice}:
      </StyledCaption>
      <Tile
        label={translations.bToC.onboardingScreens.buttonForMyself}
        Icon={PersonIcon}
        onClick={() => gotoNext(FOR_MYSELF)}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonForSomeoneElse}
        Icon={SupervisorAccountIcon}
        onClick={() => gotoNext(FOR_SOMEONE)}
        tileType={TILETYPE.B2C}
      />
    </OnboardingCard>
  );
}
