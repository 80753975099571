import { useGetProviderListing } from "apollo/hooks/queries";
import {
  FEDERAL_STATES,
  LOGO_MASTER,
  PAGINATION_COUNT_15,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { RecareLogoOnly } from "ds_legacy/components/RecareLogo";
import { B2C_PRIMARY_COLOR } from "ds_legacy/materials/colors";
import { dp, margin, responsiveCSS } from "ds_legacy/materials/metrics";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useB2CNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { B2CFilters } from "dsl/organisms/Filters/B2CApp/types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Translations from "translations/types";
import {
  MOBILE_MAX_WIDTH,
  PAGE_MAX_WIDTH,
  PageHeading,
  testIds,
} from "../common";

const LoadingCardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const ImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -10px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const HeadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${PAGE_MAX_WIDTH};
  margin: ${margin(12, 0, 6, 0)};
  align-items: flex-start;
  ${responsiveCSS({
    mobile: [`margin: ${margin(8, 0, 0, 0)}`, `max-width: ${MOBILE_MAX_WIDTH}`],
    tablet: [`margin: ${margin(8, 0, 0, 0)}`],
  })};
`;
export const LoadingCard = ({
  translations,
}: {
  translations: Translations;
}) => (
  <LoadingCardWrapper data-testid={testIds.loading}>
    <HeadingWrapper>
      <PageHeading color={B2C_PRIMARY_COLOR} bold margin={margin(2, 1)}>
        {translations.bToC.onboardingScreens.titleLoadingScreen}
      </PageHeading>
    </HeadingWrapper>
    <ImageWrapper>
      <RecareLogoOnly
        variant={LOGO_MASTER}
        style={{ height: dp(550), opacity: "0.15" }}
      />
    </ImageWrapper>
  </LoadingCardWrapper>
);

export default function Loading({
  filters,
  translations,
}: {
  filters: B2CFilters;
  translations: Translations;
}) {
  const { goToDashboard } = useB2CNavigationHandlers();
  const toast = useToast();
  const [queryProgress] = useGetProviderListing({
    filters,
    start: 0,
    count: PAGINATION_COUNT_15,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timeout);
  }, [queryProgress]);

  useEffect(() => {
    if (!isLoading) {
      if (queryProgress === QUERY_PROGRESS_FAILED) {
        goToDashboard({ filters });
        toast({
          message: translations.bToC.noResultsWithState({
            state: FEDERAL_STATES.NIEDERSACHSEN,
          }),
          color: "primary",
        });
      }
      if (queryProgress === QUERY_PROGRESS_SUCCEED) {
        goToDashboard({ filters });
      }
    }
  }, [isLoading, queryProgress]);

  return <LoadingCard translations={translations} />;
}
