import {
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SOLUTION_DAILY_LIVING_SUPPORT_SERVICES,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_MOBILE_CARE,
  TRACK_EVENTS,
} from "core/consts";
import {
  AccessibilityNew,
  HomeIcon,
  LocalHospital,
  VolunteerActivismIcon,
} from "ds/icons";
import Tile, { TILETYPE } from "ds_legacy/components/TileButton";
import {
  B2C_PRIMARY_COLOR,
  PROVIDER_PRIMARY_LIGHT_COLOR,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  dp,
  margin,
  padding,
  responsiveCSS,
  sizing,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_11,
  FONT_SIZE_16,
  Subheading,
} from "ds_legacy/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import {
  USER_FILTER_SERVICES,
  USER_FILTER_SOLUTIONS,
} from "dsl/organisms/Filters/consts";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { useTranslations } from "translations";
import OnboardingCard, {
  PAGES,
  PageHeading,
  PageProps,
  StyledCaption,
  testIds,
} from "../common";

export const CARE_SOLUTIONS = [
  SOLUTION_HELP_AT_HOME,
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
];

export default function CareSolution({
  navigateToPage,
  setFilters,
  translations,
}: PageProps) {
  const { trackEvent } = useTracking();

  const gotoNext = (solutions: Array<number>) => {
    if (solutions.includes(SOLUTION_DAILY_LIVING_SUPPORT_SERVICES)) {
      return navigateToPage(PAGES.DAILY_LIVING_SUPPORT_SERVICES);
    }

    navigateToPage(PAGES.LOCATION);
    if (solutions.includes(SOLUTION_HELP_AT_HOME))
      setFilters({
        type: USER_FILTER_SOLUTIONS,
        value: [SOLUTION_HELP_AT_HOME],
      });
    const services = solutions.filter(
      (solution) => solution !== SOLUTION_HELP_AT_HOME,
    );
    if (services.length) {
      setFilters({ type: USER_FILTER_SERVICES, value: services });
      setFilters({
        type: USER_FILTER_SOLUTIONS,
        value: [SOLUTION_MOBILE_CARE],
      });
    }

    trackEvent({
      name: TRACK_EVENTS.DASHBOARD_FILTERS,
      search_target: solutions,
    });
  };
  const { isDesktop } = useMedia();
  const withBackground = !isDesktop;
  return (
    <CareSolutionOnboardingCard
      title={translations.bToC.onboardingScreens.titleWhatMobileCare}
      onNext={() => gotoNext(CARE_SOLUTIONS)}
      data-testid={testIds.careSolution}
      withBackground={withBackground}
    >
      <StyledCaption>
        {translations.bToC.onboardingScreens.yourChoice}:
      </StyledCaption>
      <Tile
        label={translations.bToC.onboardingScreens.buttonHelpAtHome}
        onClick={() => gotoNext([SOLUTION_HELP_AT_HOME])}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonBasicCare}
        onClick={() => gotoNext([SERVICE_BASIC_CARE])}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonBasicMedicalCare}
        onClick={() => gotoNext([SERVICE_BASIC_MEDICAL_CARE])}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={
          translations.bToC.onboardingScreens.buttonDailyLivingSupportServices
        }
        onClick={() => {
          trackEvent({
            name: TRACK_EVENTS.B2C_ONBOARDING_DAILY_SUPPORT_CLICKED,
          });

          gotoNext([SOLUTION_DAILY_LIVING_SUPPORT_SERVICES]);
        }}
        tileType={TILETYPE.B2C}
      />
    </CareSolutionOnboardingCard>
  );
}

export const CareSolutionBackground = () => {
  const translations = useTranslations();
  return (
    <Background>
      <BackgroundLayout>
        <BackgroundHeading>
          {translations.bToC.onboardingScreens.infoMobileCare.title}
        </BackgroundHeading>

        <HorizontalLayout aligned>
          <HomeIcon style={{ color: B2C_PRIMARY_COLOR }} size={FONT_SIZE_16} />
          <Subheading>
            {translations.bToC.onboardingScreens.buttonHelpAtHome}
          </Subheading>
        </HorizontalLayout>
        <BackgroundText>
          {translations.bToC.onboardingScreens.infoMobileCare.infoHelpAtHome}
        </BackgroundText>

        <HorizontalLayout aligned>
          <AccessibilityNew style={{ color: B2C_PRIMARY_COLOR }} />
          <Subheading>
            {translations.bToC.onboardingScreens.buttonBasicCare}
          </Subheading>
        </HorizontalLayout>
        <BackgroundText>
          {translations.bToC.onboardingScreens.infoMobileCare.infoBasicCare}
        </BackgroundText>

        <HorizontalLayout aligned>
          <LocalHospital style={{ color: B2C_PRIMARY_COLOR }} />
          <Subheading>
            {translations.bToC.onboardingScreens.buttonBasicMedicalCare}
          </Subheading>
        </HorizontalLayout>
        <BackgroundText>
          {
            translations.bToC.onboardingScreens.infoMobileCare
              .infoBasicMedicalCare
          }
        </BackgroundText>
        <HorizontalLayout aligned>
          <VolunteerActivismIcon style={{ color: B2C_PRIMARY_COLOR }} />
          <Subheading>
            {
              translations.bToC.onboardingScreens
                .buttonDailyLivingSupportServices
            }
          </Subheading>
        </HorizontalLayout>
        <BackgroundText>
          {
            translations.bToC.onboardingScreens.infoMobileCare
              .infoDailyLivingSupportServices
          }
        </BackgroundText>
      </BackgroundLayout>
    </Background>
  );
};

const BACKGROUND_HEADING_SIZE = 22;
const BackgroundHeading = styled(PageHeading)`
  font-size: ${dp(BACKGROUND_HEADING_SIZE)};
  ${responsiveCSS({
    tablet: [`margin-left: 0`],
    desktop: [`margin-left: ${sizing(5)}`],
  })};
`;
const BackgroundText = styled(Body)`
  margin: ${margin(0, 0, 4, 5)};
  ${responsiveCSS({
    mobile: [`font-size: ${FONT_SIZE_11}`, `margin: ${margin(0, 4, 2, 5)}`],
  })};
`;
const Background = styled.div`
  height: 100%;
  width: 45%;
  max-width: ${sizing(75)};
  background: ${PROVIDER_PRIMARY_LIGHT_COLOR};
  padding-top: ${sizing(2)};
  ${responsiveCSS({
    mobile: [
      `width: 100%`,
      `padding: ${padding(4, 1, 2, 1)}`,
      `max-width: 100%`,
    ],
    tablet: [`width: 100%`, `padding: ${padding(0, 2)}`, `max-width: 100%`],
  })};

  span {
    line-height: ${sizing(2.25)};
  }
`;
const BackgroundLayout = styled(VerticalLayout)`
  margin: auto;
  max-width: ${sizing(51)};
  ${responsiveCSS({
    mobile: [`max-width: ${sizing(43)}`],
  })};
`;
const CareSolutionOnboardingCard = styled(OnboardingCard)`
  ${responsiveCSS({
    tablet: [`height: initial`, `padding-bottom: ${sizing(5)}`],
  })};
`;
