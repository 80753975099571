import { getStaticAsset } from "core/model/config";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useOnEnter } from "dsl/atoms/Routes";
import { useB2CNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import {
  B2CFilterActions,
  B2CFilters,
} from "dsl/organisms/Filters/B2CApp/types";
import { useParams } from "react-router-dom";
import { useTranslations } from "translations";
import { useB2CHeaderContext } from "../B2CDashboard/B2CHeader";
import CareSolution, { CareSolutionBackground } from "./Pages/CareSolution";
import CareType from "./Pages/CareType";
import DailyLivingSupportServices from "./Pages/DailyLivingSupportService";
import Loading from "./Pages/Loading";
import Location from "./Pages/Location";
import StartDate from "./Pages/StartDate";
import Target from "./Pages/Target";
import Term from "./Pages/Term";
import Welcome from "./Pages/Welcome";
import Stepper from "./Stepper";
import { BackgroundImage, PAGES, Page, StepperPage } from "./common";

export const DEFAULT_ONBOARDING_PAGE = "welcome";

export type B2COnboardingProps = {
  filters: B2CFilters;
  setFilters: React.Dispatch<B2CFilterActions>;
};

export default function B2COnboarding() {
  const { filters, setFilters } = useB2CHeaderContext();
  const { page = DEFAULT_ONBOARDING_PAGE } = useParams<{
    page: Page;
  }>();
  useOnEnter();
  const { goToOnboarding } = useB2CNavigationHandlers();

  const translations = useTranslations();
  const { isDesktop } = useMedia();

  const navigateToPage = (page: string) => goToOnboarding({ page });

  const PagesToJSX = {
    [PAGES.WELCOME]: Welcome,
    [PAGES.TARGET]: Target,
    [PAGES.START_DATE]: StartDate,
    [PAGES.CARE_TYPE]: CareType,
    [PAGES.CARE_SOLUTION]: CareSolution,
    [PAGES.CARE_TERM]: Term,
    [PAGES.LOCATION]: Location,
    [PAGES.LOADING]: Loading,
    [PAGES.DAILY_LIVING_SUPPORT_SERVICES]: DailyLivingSupportServices,
  };

  const ActivePage = PagesToJSX[page];

  const background =
    page !== PAGES.CARE_SOLUTION ? (
      <BackgroundImage image={getStaticAsset("b2c_onboarding_image.png")} />
    ) : (
      <CareSolutionBackground />
    );
  const showBackground = page === PAGES.CARE_SOLUTION || isDesktop;

  return (
    <StepperPage
      showBackground={showBackground}
      background={background}
      stepper={<Stepper activePage={page} />}
    >
      <ActivePage
        translations={translations}
        navigateToPage={navigateToPage}
        setFilters={setFilters}
        filters={filters}
      />
    </StepperPage>
  );
}
